


/*************************************
Menu overrides.
**************************************/
.ag-menu-option-shortcut {
    font-family: var(--xpool-font-family) !important;
}
.ag-menu-option-text {
    font-family: var(--xpool-font-family) !important;
}
.ag-tool-panel {
     border: none !important;
}
.ag-menu {
    max-height: 400px;
}
.ag-menu-column-select-wrapper {
    height: 350px;
}
.ag-theme-balham .ag-set-filter-list {
    max-height: 350px;
    height: unset;
}
.ag-filter-virtual-list-viewport {
    max-height: 350px;
}
.ag-popup-child {
    z-index: 10000;
}



/*************************************
Cell and header cell overrides.
**************************************/
.ag-cell, .ag-header-cell, .ag-header-group-cell {
    font-family: var(--xpool-font-family) !important;
    padding: 0px 8px 0px 8px !important;
    font-size: var(--xpool-grid-font-size);
    border: none !important;
}
.ag-cell, .ag-cell-inline-editing {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-body-text-color);
    border-bottom: var(--xpool-grid-row-border) !important;
}
.ag-cell:focus {
    outline: none;
}
.ag-header-cell-resize {
    /*z-index: auto !important;*/
}
.ag-theme-balham .ag-cell-data-changed {
    background: var(--xpool-grid-flash-background-color) !important;
}
.ag-header-cell, .ag-header-group-cell {
    /*fix SB styling*/
    color: var(--xpool-grid-header-text-color) !important;
    font-weight: var(--xpool-grid-header-font-weight) !important;
}
.ag-header-cell {
    border-bottom: var(--xpool-grid-header-border) !important;
}
.ag-header-group-cell {
    border-bottom: var(--xpool-grid-header-group-border) !important;
}
.ag-header-cell-moving, .ag-header-group-cell-moving {
    background-color: var(--xpool-grid-header-background-color) !important;
}





/*************************************
Header overrides.
**************************************/
.ag-header {
    border: none !important;
    background-color: var(--xpool-grid-header-background-color) !important;
}
.ag-header-row {
    border: none !important;
    background-color: var(--xpool-grid-header-background-color) !important;
}
.ag-header-container {
    border: none !important;
    background-color: white !important;
}


/*************************************
Row class overrides.
**************************************/
.ag-theme-balham .ag-row {
    transition: background-color 0.2s, color 0.2s !important;
    border: none !important;
    background-color: unset;
}
/*background: var(--xpool-grid-background-color) !important;*/
.ag-row-no-focus, .ag-row-focus {
    /*z-index: auto !important;*/ /* This did not work with inline editing and error bubbles since the z-index stacking context was npt created by the ag-row-inline-editing z-index setting */
}
.ag-row-hover {
    background: var(--xpool-grid-hover-background-color) !important;
    cursor: pointer;
}
.ag-theme-balham  .ag-row-selected {
    background: var(--xpool-grid-selected-background-color) !important;
}
.ag-theme-balham  .ag-row-editing {
    background: var(--xpool-grid-selected-background-color) !important;
}
.ag-theme-balham  .ag-cell-inline-editing {
    background: var(--xpool-grid-selected-background-color) !important;
    overflow: visible !important;
    border-bottom: var(--xpool-grid-row-border) !important;
}
.ag-theme-balham .ag-row-odd {
    background-color: unset;
}
.ag-theme-balham  .ag-row-even {
    background-color: unset;
}
.ag-details-row {
    padding-top: 10px !important;
    padding-left: 32px !important;
    padding-right: 0px !important;
    border-bottom: var(--xpool-grid-row-border) !important;
}


/*************************************
Body and viewport overrides.
**************************************/
.ag-root-wrapper {
    border: none !important;
    background-color: unset !important;
}
.ag-root {
    border: none !important;
}
.ag-body {
    border: none !important;
    border-bottom: var(--xpool-grid-row-border) !important;
}
.ag-overlay-wrapper {
    /*vertical-align: bottom !important;*/
    align-items: flex-end !important;
}
.ag-overlay-panel {
    padding-bottom: 20px !important;
}
.ag-body-viewport {
    border: none !important;
    /*
    background-color: var(--xpool-grid-background-color) !important;
    */
    /*overflow-x: hidden !important;*/
}
.ag-body-container {
    border: none !important;
    background-color: white !important;
    /*overflow-x: hidden !important;*/
}
.ag-center-cols-container {
    background-color: white !important;
}

/*************************************
Drag and drop overrides.
**************************************/
.ag-column-drop-horizontal, .ag-column-drop {
    border-bottom: var(--xpool-grid-row-border) !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: var(--xpool-grid-header-background-color) !important;
}
.ag-column-drop-cell {
    border: var(--xpool-grid-row-border) !important;
    background: rgba(0,0,0,0.2) !important;
}


/*************************************
Other overrides.
**************************************/

/* Needed to not clip last pixels from group header row content*/
.ag-group-value {
    padding-right: 2px;
}
.ag-side-bar, .ag-status-bar {
    border-right-style: none !important;
    border-bottom-style: none !important;
}
.ag-theme-balham {
    background-color: unset !important;
}

.ag-cell-value {
    overflow-x: hidden !important;
    overflow-y: visible !important;
}
