.bankHolidaysFilterButtonsGroup {
    min-width    : 400px;
    max-width    : 600px;
    width        : 30%;
}
.bankHolidaysFilterSelect {
    /*padding-right: 30px;*/
    min-width    : 80px;
    max-width    : 150px;
    width        : calc(13% - 20px);
}
.bankHolidaysFilterDate {
    min-width    : 100px;
    max-width    : 120px;
    width        : calc(10% - 20px);
}
.rbc-row-content {
    z-index: unset !important;
}
.bankHolidayIndicatorBase {
    font-size: var(--xpool-table-font-size);
    font-weight: 600;
    color: var(--xpool-body-text-color);
    /*
    line-height: 17px;
    height: 17px;
    */
    /*height: 17px;*/
    display: inline;
    /*
    position: absolute;
    top: 3px;
    left: 3px;
    */
    /*background-color: red;*/
    width: 40px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: default;
}
.bankHolidayIndicatorNoHolidays {
    background-color: rgb(31,31,31);
}
.bankHolidayIndicatorHolidays {
    background-color: var(--xpool-user-message-warning-background-color);
}
.bankHolidayIndicatorNoRunDate {
    background-color: var(--xpool-user-message-error-background-color);
}
