.filterFormContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: var(--xpool-grid-row-border);
    vertical-align: middle;
    /*margin-top: -10px;*/
    margin-top: 5px;
    padding-bottom: 3px;
    /*width: calc(100% + 30px);*/
}
.filterFormField {
    margin-right : 10px;
    vertical-align: middle;
}
.filterFormButton {
    vertical-align : middle;
    padding-top    : 0;
    display        : inline-block;
    float          : right;
    margin-left    : 10px;
    margin-right   : -7px;
    margin-top     : 6px;
}
.filterFormRightButtonsGroup {
    /*padding-right: 30px;*/
    vertical-align: middle;
    margin-left  : auto;
}
