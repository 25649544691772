.currencyList{
    width: 100%;
}
.currencySelect{
    width: 70%;
}
.currencyRow{
    width: 100%;
    display: flex;
}
.selectedAgreements {
    padding-left: 5px;
}
.agreementDataColumn {
    display: inline-block;;
    width: calc(50% - 5px);
    vertical-align: top;
}
.selectedCurrencies{
    text-align: left;
    margin-bottom: 4px;
    margin-right: 15px;
    margin-top: 4px;
    width: 60px;
    white-space: nowrap;
    overflow: visible;
    float: left;
}
.removeCurrencies{
    width: 9%;
    text-align: left;
    margin-bottom: 12px;
    float: left;
}
.halfFormContainer{
    width: 100%;
    display: block;
}
.inputText{
    font-size: smaller;
}
.halfForm{
    width: 49%;
}
.selectContainer {
    width: 60%;
}
.saveButton {
    display: block;
    width: 100%;
}
div.currencyPairContainer{
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.currencyPairLabel{
    text-align: center;
    height: 30px;
    width: 65px;
    padding: 6px;
    margin: 3px 5px 4px;
    background-color: #3f51b5;
    color: white;
    font-size: medium;
    font-weight: bold;
    vertical-align: middle;
    line-height: 22px;
}
.agreementFormRow {
    width: 100%;
    display: block;
    margin: 15px 10px;
}
.agreementFormRowGrid {
    display: grid;
    width: 100%;
    grid-template-columns: 75px 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-template-areas: "currencyPairHeading tenor period dispatchLevel dispatchPeriod"
                         "addSameCurrencyPair priceType gracePeriod openTime closeTime";
    align-items: start;
    justify-items: start;
}
.agreementFormRowGridFull {
    display: grid;
    width: 100%;
    grid-template-columns: 75px 3fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-template-areas: "currencyPairHeading tenor openTime closeTime"
                         "addSameCurrencyPair tenor openTime closeTime";
    align-items: start;
    justify-items: start;
}
.accountFormSweepTopUp {
    float: left;
}
.accountFormSweepTopUpRow {
    width: 99%;
    display: inline-block;
}
.agreementFormAddRemoveContainer{
    width: 99%;
    display: inline-block;
}
.agreementFormCell {
    float: left;
    margin-right: 10px;
}
.agreementFormCellFull {
    float: left;
    margin-right: 10px;
    height: 100%;
    width: 100%;
}
.firstFieldContainer{
    width: 100%;
    margin-top: 10px;
}
.shadow {
    -webkit-box-shadow: 5px 3px 3px 1px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    5px 3px 3px 1px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         5px 3px 3px 1px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.currencyPairShadow {
    -webkit-box-shadow: 3px 2px 2px 1px #0000A0;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 2px 2px 1px #0000A0;  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 2px 2px 1px #0000A0;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.submitRow {
    text-align: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}
.singleAdminColumn {
    min-width: 500px;
    display: block;
    box-sizing: border-box;
    margin-right: 0;
}
.formRow {
    width: 100%;
    display: inline-block;
}
.formCell {
    float: left;
    padding-right: 15px;
}
.separate {
    margin-left: 55px;
}
.baseCurrencyLabel {
    font-size: 18px;
    font-weight: normal;
    margin-top: 20px;
}
.wizardFormContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "wizardFormColumn1 wizardFormColumn2 wizardFormColumn3";
}
.wizardFormContainerRight {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "wizardFormColumn1 wizardFormColumn2 wizardFormColumn2";
}
.wizardFormColumn1 {
    display: block;
    padding: 10px;
    grid-area: wizardFormColumn1;
}
.wizardFormColumn2 {
    display: block;
    padding: 10px;
    grid-area: wizardFormColumn2;
}
.wizardFormColumn3 {
    display: block;
    padding: 10px;
    grid-area: wizardFormColumn3;
}
