

/***********************************/
/*      Field line styling         */
/***********************************/
.xpFormFieldLine {
    position: relative;
    display: block;
    margin-bottom: 5px;
}
.xpFormFieldLine:last-child {
    /*margin-bottom: 0;*/
}
.xpFormFieldInline {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 10px;
}
.xpFormFieldInline:last-child {
    /*margin-bottom: 0;*/
}
.xpFormFieldLineNoBottomMargin {
    position: relative;
    display: block;
    margin-bottom: 0px;
}


/***********************************/
/*      Input element styling      */
/***********************************/
.xpFormInputAlignRight {
    padding: 0 5px 0 5px;
    text-align:right;
    white-space: nowrap;
}
.xpFormInputNoLabel {
    padding-top: 7px;
    font-size: var(--xpool-table-font-size);
    height: calc(var(--xpool-line-height) + 4px);
    line-height: calc(var(--xpool-line-height) - 4px);
}
.xpFormInputTableCell {
    border-width: 0 1px 0 1px !important;
    border-style: solid !important;
    border-color: whitesmoke !important;
    border-radius: 0 !important;

    padding-top: 10px !important;
    font-size: var(--xpool-table-font-size) !important;
    /*height: calc(var(--xpool-line-height) + 3px) !important;*/
    /*line-height: calc(var(--xpool-line-height) - 3px) !important;*/
}
.xpFormCheckboxInTable {
    height: calc(var(--xpool-line-height) + 3px);
    text-align: center;
    padding-top: 0px;
    padding-left: 12px;
    margin-right: -10px;
    margin-top: -3px;
}

/***********************************/
/*  Current value element styling  */
/***********************************/
.xpFormValueTextAlignRightInner {
    float: right;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    min-height: var(--xpool-line-height);
    margin: 0 0 0 0;
}
.xpFormValueTextInner {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    min-height: var(--xpool-line-height);
    margin: 0 0 0 0;
}
.disabledFieldAlignRight {
    padding-right: 5px;
    text-align: right;
}

/***********************************/
/*      Error element styling      */
/***********************************/
.xpFormError {
    position: absolute;
    font-size: var(--xpool-label-font-size);
    color: var(--xpool-form-error-text-color);
    animation: slide-down 0.3s backwards ease-in-out;
    font-weight: var(--xpool-standard-font-weight);
    /*background: var(--xpool-form-error-background-color);*/
    background: rgba(255,150,150,0.8);
    /*border: 1px solid slategray;*/
    /*border-radius: 3px;*/
    padding: 2px 5px 2px 5px;
    z-index: 1;
    text-wrap: normal;
    white-space: normal !important;
}
.xpFormErrorBelow {
    margin-top: -2px;
    top: 100%;
    right: 20px;
    max-width: calc(100% - 60px);
    min-width: 80px;
}

/***********************************/
/*  Before change element styling  */
/***********************************/
.xpFormBeforeChangeInInputCell {
    position: absolute;
    top: -4px;
    z-index: 1;
}
.xpFormBeforeChangeInInputCellAlignRight {
    position: absolute;
    top: -4px;
    right: 5px;
    z-index: 1;
}
.xpFormBeforeChangeInCheckboxInputCell {
    position: absolute;
    top: -4px;
    left: -2px;
    z-index: 1;
}
.xpFormBeforeChangeUnderInlineLabel {
    position: absolute;
    top: 18px;
    left: 0px;
}
.xpFormToggleFieldBeforeChange {
    text-align: center;
    width: 100%;
    height: 11px;
    margin-top: -10px;
    margin-left: -1px;
}

/***********************************/
/*     Label element styling       */
/***********************************/
.xpFormLabel {
    margin: 0 10px 0 0;
}

/***********************************/
/*     Radio element styling       */
/***********************************/
.xpFormRadioButtonWrapper {
    display: inline-block;
    vertical-align: baseline;
    height: 13px;
    width: 13px;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 3px -1px 0;
}
.xpFormRadioButtonWrapper:focus {
    box-shadow: var(--xpool-focus-box-shadow);
}
.xpFormRadioLabel {
    display: inline-block;
    margin: 0px 14px 0 0;
}
