.react-grid-layout {
}

.react-grid-item {
    /*overflow-y: auto !important;*/
    /*position: relative !important;*/
}

.react-resizable-handle {
    /*position: fixed !important;*/
}

/*
.react-draggable-placeholder {
    background-color: yellow;
}
*/
.react-grid-placeholder {
    background-color: yellow;
}

.react-resizable-hide {
    background-color: yellow;
}

.react-grid-item.react-grid-placeholder {
    background: var(--xpool-secondary-color-mid) !important;
}

.dashboardContainer {
    width: 100%;
}
.dashboardHolder {
    height: 100%;
    overflow-y: auto;
    border: solid 1px whitesmoke;
    border-radius: 7px;
}
.dashBoardComponentContent {
    height: calc(100% - 42px);
}
.dashboardPicker {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-top: -10px;
    height: 40px;
    padding: 4px;
    background-color: var(--xpool-grid-header-background-color);
    color: white;
    border-radius: 5px;
}
.dashboardCanvas{
    width: calc(100% + 20px);
    min-height: 50px;
    margin-left: -10px;
    padding-top: 5px;
}

.layout {
    background: white;
}

.dashboardIcon {
    position: relative;
    display: inline-block;
    float: left;
    overflow: hidden;
    padding: 0;
    margin-top: 0px;
    margin-left: 5px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: var(--xpool-primary-color-strong);
    /*border: var(--xpool-button-border);*/
    text-align: center;
    vertical-align: middle;
    z-index: 10;
}
.dashboardIcon:first-child {
    margin-left: 0px;
}

.dashboardIcon:hover {
    /*border: var(--xpool-button-hover-border);*/
    background: var(--xpool-primary-color-mid);
    cursor: pointer;
}
.dashboardIconInComponent {
    background: var(--xpool-primary-color-strong);
    width:  22px;
    height: 22px;
    padding: 2px 2px 2px 2px;
}
.dashboardIconInComponent:hover {
    background: var(--xpool-primary-color-mid);
    /*border: var(--xpool-button-border);*/
}

.center-div {
    display: flex;
    margin: 0 auto;
    padding-top: 5px;
    padding-right: 0px;
    width: 32px;
    height: 32px;
}
.icon-i {
    margin: auto; /* Important */
    padding-right: 0px;
    text-align: center;
}


.dashBoardComponentHeader {
    position: sticky;
    z-index: 1;
    top: 0;
    display: inline-block;
    width: 100%;
    border: none;
    border-radius: 6px 6px 0 0;
    padding: 5px;
    margin-bottom: 2px;
    text-align: left;
    background-image: linear-gradient(to top, RGB(0,0,0,0) 0%, var(--xpool-secondary-background-color)); /* Standard syntax (must be last) */
}
.dashBoardComponentHeaderInput {
    float: left;
    resize: none;
    font-weight: var(--xpool-header-font-weight);
    font-size: 20px;
    text-align: center;
    width: 100%;
    height: 25px;
    line-height: 25px;
    color: var(--xpool-header-text-color);
    overflow: no-display;
}
.dashBoardComponentInputWrapper {
    padding-right: 55px;
    padding-left: 5px;
}
.dashBoardComponentHeaderText {
    font-family: var(--xpool-header-font-family);
    font-weight: var(--xpool-header-font-weight);
    font-size: 20px;
    text-align: center;
    padding-right: 35px;
    padding-left:  35px;
    width: 100%;
    height: 25px;
    line-height: 25px;
    margin-bottom: 5px;
    color: var(--xpool-header-text-color);
    cursor: pointer;
    overflow: no-display;
    white-space: nowrap
}
.closeDashboardComponentButton {
    float: right;
    opacity: 0.3;
    /*display: inline-block;*/
    text-align: right;
    margin-left: 2px;
    margin-top: 2px;
    height: 20px;
    padding: 0;
    vertical-align: middle;
    border: none;
}

.closeDashboardComponentButton:hover {
    border-color: darkslategray;
    cursor: pointer;
    opacity: 0.5;
}
.closeDashboardComponentButton:active {
    border-color: darkslategray;
    cursor: pointer;
    opacity: 1;
}
.closeDashboardComponentButtonInactive {
    opacity: 0.1;
    /*display: inline-block;*/
}

.closeDashboardComponentButtonInactive:hover {
    cursor: not-allowed;
    opacity: 0.1;
}
.closeDashboardComponentButtonInactive:active {
    cursor: not-allowed;
    opacity: 0.1;
}



.react-grid-item {
    overflow: hidden;
    /*background-image: linear-gradient(to bottom, RGB(0,0,0,0) 70%, var(--xpool-grid-hover-background-color)); !* Standard syntax (must be last) *!*/
    background-image: linear-gradient(to bottom, RGB(0,0,0,0) 70%, ghostwhite); /* Standard syntax (must be last) */
    border-radius: 7px;
}
.dashboard-dummy-component{
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 10px;
    border-radius: 7px 7px 0 0;
    background-image: linear-gradient(to bottom, RGB(0,0,0,0) 70%, var(--xpool-grid-hover-background-color));
}
