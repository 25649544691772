

/*.adminEntityCard {
    width: calc(100% - 30px);
    margin-top: 10px;
    margin-left: 30px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    padding-top: 5px;
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: ghostwhite;
}*/


.xpAdminFormEntityCard {
    width: 100%;
    margin-top: 10px;
    padding: 7px;
    /*
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    padding-top: px;
    */
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: var(--xpool-primary-background-color);
}
.xpAdminFormEntityCardNewStyling {
    width: 100%;
    /*margin-top: 10px;*/
    padding: 7px;
    border: none;
    background: var(--xpool-primary-background-color);
}
.xpAdminFormEntityCardIndent{
    width: calc(100% - 30px);
    margin-left: 30px;

}
.adminEntityCardEdit {
    background-color: var(--xpool-form-card-update-background-color);
    /*background: #E7FFFF;*/
}
.adminEntityCardNew {
    background-color: var(--xpool-form-card-new-background-color) !important;
    padding-bottom: 7px;
    /*background: #E7FFFF;*/
}
.adminEntityCardDisabled {
    background-color: var(--xpool-form-card-disabled-background-color) !important;
    /*background: #E7FFFF;*/
}
.ownedByParentRunConfig {
    /*background-color: rgba(179,255,185,0.5);*/
    background-color: rgba(230,230,230,0.5);
}
.formCard {
    /*
    width: calc(100%-30px);
    */
    margin-top: 5px;
    margin-left: 0px;
    margin-bottom: 5px;
    padding-left:  7px;
    padding-right: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: ghostwhite;
}
.adminEntityCardSubForm {
    padding-top:   0px;
    padding-right: 0px;
    margin-top:   -0px;
    margin-left:  0px;
    padding-left: 0px;
    border-style: none;
    background: none;
    width: 100%;
}
.adminEntityCard1Col {max-width: 400px;}
.adminEntityCard2Col {max-width: 800px;}
.adminEntityCard3Col {max-width: 1200px;}

.adminFormContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 30px;
    padding-left: 5px;
    padding-right: 5px;
    /*width: calc(100% + 30px);*/
}

h3.userPrefsCardHeader {
    margin-bottom: 10px;
    padding-bottom: 5px;
    text-align: center;
}
.userPrefsCard {
    margin-bottom: 30px;
}

.formContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}
.adminColumn {
    min-width    : 340px;
    max-width    : 700px;
    flex: 1 1 auto;
    width: 10%; /* This is a bit strange, but together with the flex setting it works for all column numbers. */
}
.admin1Column {}
.admin2Column {}
.admin3Column {}
.admin4Column {}

.ormFormButtons {
    padding-bottom: 0px;
}

.entitySubItemsHeader {
    width: 100%;
    padding-left:  7px;
    padding-right: 0px;
    border-radius: 0px;
    border-color: lightgray;
    border-bottom-style: none;
    border-width: 1px;
    background: ghostwhite;
    margin-bottom: -0px;
}
.entitySubItemsHeaderNoBackground {
    background: none;
}
.entitySubItemsHeaderText {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top:    calc( var(--xpool-button-height) - 10px) !important;
    padding-bottom:  2px !important;
}
.formHeaderEditNew {
    border-bottom: solid lightgray 1px;
    margin-bottom: 6px;
    padding-top: 0px;
    padding-bottom: 9px;
}

.entitySubItemsContainer {
    background: white;
    /*
    padding-bottom: 7px;
    */
    /*
    margin-bottom: 7px;
    */
}
.entitySubItemsContainerWithTopBorder {
    border-radius: 0px;
    border-color: lightgray;
    border-top-style: solid;
    border-width: 1px;
    margin-top: 7px;
}

.entitySubItemsList {
    /*margin-top: -5px;*/
    margin-top: 0px;
    background: white;
}
/*
.adminColumn:first-child {padding-left : 0px}
.adminColumn:last-child  {padding-right: 0px}
*/

.dateTimeFormSection {
    padding-top: 2px;
    padding-bottom: 10px;
}

.field {
    position: relative;
    display: block;
    margin-bottom: 5px;
}

.tableInForm {
    margin-bottom: 10px;
}

.formFieldFlex {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 10px;
}
.formFieldFlex:last-child {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2px;
}

.searchField {
    width: calc(100% - 24px);
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
}
.tableField {
    position: relative;
    display: block;
    margin: 0;
}

.inlineFormField {
    display: inline-block;
    padding-right: 10px;
}
.inlineFormField:last-child {
    display: inline-block;
    padding-right: 0px;
}

.formFieldsGroup {
    padding: 0px  5px  0px  4px;
    margin:  0px -6px 0px -5px;

    border: 1px solid rgba(0,0,0,0);
    border-radius: 5px;

    transition-duration: 250ms;
    transition-property: border;
    transition-timing-function: ease-in-out;
}
.formFieldsGroupExpanded {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-color: lightgrey;
}

.formFieldsGroupController {
    display: inline-block;
    width: calc(100% - 23px);
    /*padding-bottom: 5px;*/
}
.formFieldsGroupRemoveButton {
    float: right;
    margin-top: 2px;
    margin-right: 0px;
    /*padding-bottom: 5px;*/
}
.formFieldsGroupContent {
    padding: 0px 7px 0 0;
}
.formFieldsGroupContentStyle {
    transition-duration: 300ms;
    transition-property: height;
    transition-timing-function: ease-out;
    padding-left: 2px;
    /*overflow: hidden;*/
}

.runConfigurationTypeList {
    border-bottom: solid 1px lightgray;
    margin-bottom: 5px;
}

.formFieldsGroupContainerCloseTransition {
    /*height: 0;*/
    /*padding: 0 !important;*/
    /*border: 0;*/
    /*margin: 0;*/
    transition: height 0.3s ease-out, padding 0.3s ease-out, margin 0.3s ease-out, border 0.3s ease-out;
    overflow: hidden;
}

.inlineCheckBox {
    position: relative;
    display: inline-block;
    min-width: 100px;
}
.inlineCheckBoxWeekdays {
    display: inline-block;
    /*min-width: 58px;*/
}

.highlightChangedFormField {
    background-color: var(--xpool-form-updated-field-background-color);
    /*color:            var(--xpool-form-before-change-text-color);*/
    /*border: 1px solid slategray;*/
    border-radius: 0px;
}

.removedValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    color: gray;
}
.removedNumberValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    color: gray;
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
}
.addedValue {
    color: blue;
    font-size: var(--xpool-table-font-size);
}
.addedNumberValue {
    color: blue;
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
    font-size: var(--xpool-table-font-size);
}
.modifiedValue {
    font-size  : var(--xpool-table-font-size);
    line-height: var(--xpool-line-height);
    color      : var(--xpool-form-before-change-text-color);
}
.noChangeValue {
    font-size: var(--xpool-table-font-size);
    line-height: var(--xpool-line-height);
}
.modifiedNumberValue {
    text-align: right;
    padding-right: 7px;
    padding-left: 0px;
    line-height: var(--xpool-line-height);
    font-size: var(--xpool-table-font-size);
}
.beforeValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    /*color: var(--xpool-header-text-color);*/
    color:            var(--xpool-form-before-change-text-color);
    margin-left: 5px;
}
.beforeNumberValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    /*color: var(--xpool-header-text-color);*/
    color:            var(--xpool-form-before-change-text-color);
    text-align: right;
    padding-right: 7px;
    padding-left: 0px;
    margin-top: 5px;
}
.beforeCheckboxValue {
    border: solid 1px var(--xpool-form-before-change-text-color);
    border-radius: 2px;
    height: 18px;
    padding-top: 2px;
    transform: scale(0.8);
}
.noChangeCheckboxValue {
    margin: 6px 0 0 0;
}
.removedCheckboxValue {
    height: 18px;
    margin-top: 2px;
    transform: scale(0.8);
}

.checkBoxFieldInTable {
    text-align: center;
    /*padding: 5px 0 0 0;*/
}
/*
td input[type="checkbox"] {
    margin: 0 0 0 0;
}
*/

/*.oldValue {
    position: absolute;
    top: 18px;
    left: 10px;
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-old-value-text-color);
    !*color: #313131;*!
    margin: 0 0 0 0;
    padding: 4px 0 0 5px;
}*/

.oldValueField {
    position: absolute;
    top: -10px;
    left: 50px;
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-header-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
}
.oldValueFieldInGrid {
    position: absolute;
    top: -2px;
    left: 2px;
    /*color: #313131;*/
    margin: 0 0 0 -5px;
    padding: 0 0 0 8px;
}
.oldValueFieldInTable {
    position: absolute;
}
.oldValueSpan {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    /*color: var(--xpool-header-text-color);*/
    color:            var(--xpool-form-before-change-text-color);
    padding-left: 2px;
    /*margin-left: 5px;*/
    display: inline-block;
}

/*
.errors {
    position: absolute;
    font-size: var(--xpool-label-font-size);
    color: var(--xpool-form-error-text-color);
    !*margin-top: -5px;*!
    margin-top: -3px;
    animation: slide-down 0.3s backwards ease-in-out;
    font-weight: var(--xpool-standard-font-weight);
    background: var(--xpool-form-error-background-color);
    border: 1px solid slategray;
    border-radius: 3px;
    padding: 5px;
    z-index: 1;
    !*left: 10px;*!
    top: 100%;
    right: 30px;
    max-width: calc(100% - 60px);
    min-width: 80px;
    text-wrap: normal;
}
.errorsInGrid {
    background: rgb(255,202,192);
    margin-top: -2px;
    padding: 0px 5px 0px 5px;
    width: calc(100% - 10px);
    max-width: unset;
    white-space: normal;
    text-align: left;
    line-height: 22px;
    z-index: 1000;
}*/
.animationRight {
    animation: slide-right 0.3s backwards ease-in-out;
}
.animationLeft {
    animation: slide-left 0.3s backwards ease-in-out;
}
.animationDown {
    animation: slide-down 0.3s backwards ease-in-out;
}
.animationGrowDown {
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center
}
.animationShrinkUp {
    animation: shrinkUp 250ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes slide-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-left {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-down {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
        opacity: 0;
    }
    /*
    80% {
        transform: scaleY(1.05);
    }
    */
    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}
@keyframes shrinkUp {
    0% {
        transform: scaleY(1);
        opacity: 1;
    }
    /*
    20% {
        transform: scaleY(1.05);
        opacity: 1;
    }
    */
    100% {
        transform: scaleY(0);
        opacity: 0;
    }
}

label.inlineLabel {
    position: relative;
    left: 0;
    display: inline-block;
    width: 65%;
    margin:   0;
    padding:  0px 0px 0px 0px;
}
.inlineLabelFlex {
    width:    65%;
    margin:   0;
    padding:  2px 0px 0px 0px;
}
.resetWidthToNone {
    width: unset !important;
}

/*
label.inlineLabelTime {
    display: inline-block;
    width: 65%;
    margin:   0;
    padding:  0px 0px 0px 5px;
}
*/

/*
.inlineLine {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0px;
    margin-bottom: 10px;
}
*/
.inlineLineFlex {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;

    margin-top: 0px;
    margin-bottom: 10px;
}

.inlineField {
    display: inline-block;
    width: 35%;
    margin-top: 0;
    text-align: right;
    padding-right: 5px;
}
.inlineFieldContainer {
    display: inline-block;
    width: 35%;
    margin-top: 0;
}
.fieldTime {
    display: inline-block;
    width: 100%;
    margin-top: 0;
    padding-right: 5px;
    text-align: right;
}
/*
.inlineFieldTime {
    display: inline-block;
    width: 35%;
    margin-top: 0;
    text-align: right;
    padding-right: 5px;
}
*/

input.inlineField,
select.inlineField
{
    display: inline-block;
    width: 35%;
    padding-right: 5px;
}

.valueText {
    display: block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 0px;
}
.valueTextSmall {
    font-size: var(--xpool-label-font-size);
    line-height: var(--xpool-label-height);
    height: var(--xpool-label-height);
    font-weight: var(--xpool-label-font-weight);
    color: var(--xpool-label-text-color);
    white-space: pre-line;
}
.valueTextRightAligned {
    display: block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 5px 0 0px;
    text-align: right;
}
.centeredText {
    align-content: center;
    text-align: center;
}

.valueTextDark {
    display: block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-black-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    white-space: pre-line;
}


.inlineValueNumber {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    text-align: right;
    width: 35%;
}
.inlineValueTime {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 5px 0 5px;
    text-align: right;
    width: 35%;
}

.inlineValueTextDark {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-black-text-color);
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    width: 35%;
}

.inlineValueText {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    width: 35%;
}
.inlineValueTextRight {
    padding: 0 5px 0 5px;
    text-align:right;
    white-space: nowrap;
}
.inlineValueTextRightInner {
    float: right;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    min-height: var(--xpool-line-height);
    margin: 0 0 0 0;
}

.inputNumber {
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
}
.inputTableCell {
    /*padding-right: 5px;*/
    font-size: var(--xpool-table-font-size);
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: whitesmoke;
    border-radius: 0;
    padding-left: 0px;
}
.inputTime {
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
}


input,
select,
textarea {
    background-color: var(--xpool-form-field-background-color);

    /*
    border-color:  var(--xpool-form-field-border-color);
    border-width:  var(--xpool-form-field-border-width);
    */
    border:  var(--xpool-form-field-border);
    border-radius: var(--xpool-form-field-border-radius);

    height: var(--xpool-line-height);
    line-height: var(--xpool-line-height);
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    resize: vertical;
    color: var(--xpool-form-input-text-color);
    /*color: #262626;*/
}

textarea {
    height: 30px;
}

label {
    /*display: block;*/
    font-size: var(--xpool-label-font-size);
    line-height: var(--xpool-label-height);
    height: var(--xpool-label-height);
    font-weight: var(--xpool-label-font-weight);
    color: var(--xpool-label-text-color);
    margin: 5px 0px 0px 0px;
}

label.inlineValueLabel {
    display: inline-block;
    margin:  10px 0px 0px 0px;
    padding:  0px 0px 0px 5px;
}
label.buttonIconLabel {
    display: inline-block;
    margin:  0;
    padding: 0 0 0 5px;
}
label.buttonIconLabel:hover {
    cursor: pointer;
}

.labelRadiosDark {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-label-font-weight);
    line-height: var(--xpool-line-height);
    margin: 0 16px 0 0;
}

.labelRadios {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    line-height: var(--xpool-line-height);
    margin: 2px 16px 0 0;
}

.labelCheckboxDark {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-label-font-weight);
    margin: 5px 0.5rem 0 0;
}

.labelCheckbox {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-label-font-weight);
    margin: 0px 8px 0 0;
}

input[type="select"] {
    margin: 5px 0px 5px 0px;
}
input[type="radio"] {
    /*appearance: radio;*/
    vertical-align: text-top;
    height: 13px;
    width: auto;
}
input[type="radio"],
input[type="checkbox"] {
    border: none;
    display: inline-block;
    font-weight: var(--xpool-standard-font-weight);
}

input[type="checkbox"] {
    /*appearance: checkbox;*/
    margin: 1px 6px 0px 0px;
    height: 16px;
    width: auto;
}
.field > input[type="checkbox"] {
    vertical-align: middle;
}



textarea:disabled,
input[type="radio"]:disabled,
input[type="checkbox"]:disabled,
input:disabled,
select:disabled {
    background-color: var(--xpool-form-disabled-background-color);
    border: none;
    font-weight: var(--xpool-field-value-font-weight);
    color: var(--xpool-body-text-color);
}

/*textarea:focus,
input[type="radio"]:focus,
input[type="text"]:focus,
input[type="checkbox"]:focus,
input:focus,
select:focus {
    !*border-width: 2px;*!
    outline: none;
    !*box-shadow:none;*!
    box-shadow: var(--xpool-focus-box-shadow);
}*/

textarea:required,
input:required,
select:required {
    outline: none;
    box-shadow:none;
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container {
    width: 100%;
}
ul.react-datepicker__time-list {
    padding: 0px 5px 0px 5px !important;
}

button.xPoolButton {
    position: relative;
    appearance: none;
    padding: 0.05rem 1rem;
    text-transform: none;
    font-size: var(--xpool-button-font-size);
    font-weight: var(--xpool-button-font-weight);
    color: var(--xpool-button-text-color);
    height: var(--xpool-button-height);

    border-width: 1px;
    border-style: solid;
    border-color: var(--xpool-primary-color-mid);
    border-radius: var(--xpool-button-border-radius);

    margin: 7px 7px 0 0;
    background-color: var(--xpool-button-background-color);
    transition: background 0.3s, border 0.3s, color 0.3s;
}

button.xPoolButton:focus {
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    box-shadow: var(--xpool-focus-box-shadow);
    outline: none;
}
button.xPoolButton:hover {
    background-color: var(--xpool-button-hover-background-color);
    color: var(--xpool-button-hover-text-color);
}
button.xPoolButton:active {
    background-color: var(--xpool-button-active-background-color);
}
button.xPoolButton:disabled {
    background-color: var(--xpool-disabled-color);
    border-color: var(--xpool-disabled-color);
}

/*
button.xPoolButton {
    position: relative;
    appearance: none;
    padding: 0.05rem 1rem;
    text-transform: var(--xpool-button-text-transform);
    font-size: var(--xpool-button-font-size);
    font-weight: var(--xpool-button-font-weight);
    color: var(--xpool-button-text-color);
    height: var(--xpool-button-height);
    border: var(--xpool-button-border);
    border-radius: var(--xpool-button-border-radius);
    margin: 7px 7px 0 0;
    background-color: var(--xpool-button-background-color);
    transition: background 0.3s, border 0.3s, color 0.3s;
}

button.xPoolButton:focus {
    !*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*!
    box-shadow: var(--xpool-focus-box-shadow);
    outline: none;
}
button.xPoolButton:hover {
    background-color: var(--xpool-button-hover-background-color);
    border: var(--xpool-button-hover-border);
    color: var(--xpool-button-hover-text-color);
    font-weight: var(--xpool-button-hover-font-weight);
}
button.xPoolButton:active {
    background-color: var(--xpool-button-active-background-color);
    color: var(--xpool-button-active-text-color);
    border: var(--xpool-button-active-border);
    font-weight: var(--xpool-button-hover-font-weight);
}
button.xPoolButton:disabled {
    background-color: var(--xpool-button-disabled-background-color);
    border: var(--xpool-button-disabled-border);
    color: var(--xpool-button-disabled-text-color);
    font-weight: var(--xpool-standard-font-weight);
}
*/

button.xPoolButtonFloatRight {
    float: right;
    margin: 7px 0px 0 7px;
}
button.xPoolButtonSmallPure {
    height: 24px;
    margin: 0;
    padding: 2px 5px 2px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
}
button.xPoolButtonInTable {
    height: 24px;
    margin: 3px 0px 3px 7px;
    padding: 2px 5px 2px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
}
button.xPoolButtonAgGrid {
    height: 22px;
    margin: 3px 0px 3px 7px;
    padding: 2px 5px 4px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
    line-height: 15px;
}
.waitingButtonSpinner {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 180%;
}

button.floatRight {
    float: right;
    margin-right: 0px;
    margin-left:  7px;
}


div.formItemPicker{
    display: grid;
    grid-template-areas:    "itemPickerLabel"
                            "available";
    grid-template-columns:  1fr;
    grid-column-gap: 5px;
    align-items: start;
    justify-items: start;
    height: 100%;
    width: 100%;
}
.itemPickerLabel{
    grid-area: itemPickerLabel;
    height: 20px;
    width: 100%;
}
.available{
    grid-area: available;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
}
/*
.pickItem{
    flex: 1 0 15%;
    border: solid thin black;
    text-align: center;
    font-size: small;
    width: 25px;
    max-width: 25px;
    margin: 5px;
}
.toPick{
    background-color: var(--xpool-button-disabled-background-color);
    color: #8C8C8C;
}
.picked{
    background-color: var(--xpool-button-active-background-color);
    color: white;
}
*/

.xpFormMultipleFieldsLine {
    display: flex;
    /*margin-top: 10px;*/
}
