
h3.xpAccordionHeader {
    font-size:   19px;
    margin:  0;
    padding: 0;
}
.xpAccordionDisabledHeader {
    margin:  0;
    padding: 0;
    /*color: darkgray;*/
    color: gray;
    font-weight: 400;
    /*font-size:   19px;*/
}
