.noRunDateSelect {
    display: inline-block;
    width: 30%;
    max-width: 250px;
    min-width: 150px;
    margin-top: 0;
    padding-right: 10px;
}
.noRunDateTableLabel {
    /*border-bottom: var(--xpool-grid-row-border);*/
    margin-bottom: 5px;
    min-width: 400px;
}