@import url(https://fonts.googleapis.com/css?family=Lato:400,300);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:200,400,600);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:200,400,600);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:200,400,600);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Serif:200,400,600);
@import url(https://fonts.googleapis.com/css?family=Yantramanav:200,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:200,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Yantramanav:200,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Tinos:ital@1&display=swap);
div.wizard{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr 5fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:    "wizardReset wizardTop wizardTop wizardFileReader"
                            "wizardDivider wizardDivider wizardDivider wizardDivider"
                            "wizardStep wizardStep wizardStep wizardStep"
                            ". wizardContent wizardContent ."
                            "wizardSummary wizardSummary wizardSummary wizardSummary";
    align-items: start;
    justify-items: start;
}
div.wizardTop{
    grid-area: wizardTop;
    width: 100%;
    height: 40px;
    text-align: center;
}
div.wizardReset{
    grid-area: wizardReset;
    width: 100%;
    height: 40px;
    text-align: center;
}
div.wizardFileReader{
    grid-area: wizardFileReader;
    width: 100%;
    height: 40px;
    text-align: center;
}
div.wizardDivider{
    grid-area: wizardDivider;
    width: 100%;
    min-height: 15px;
}
div.wizardBack{
    grid-area: wizardBack;
    width: 100%;
    text-align: center;
}
div.wizardStep{
    grid-area: wizardStep;
    width: 100%;
}
div.wizardForward{
    grid-area: wizardForward;
    width: 100%;
    text-align: center;
}
div.wizardContent{
    grid-area: wizardContent;
    width: 100%;
    padding: 0 15px 7px 15px;
    border-bottom: 1px solid lightgrey;
}
.wizardContentPlacer{
    min-height: 500px;
    width: 100%;
    /*
    padding-bottom: 7px;
    */
}
.fileReaderSpace {
    width: 100%;
    min-height: 50px;
}
.wizardFormPlacer {
    min-height: 440px;
    width: 100%;
}
div.wizardSummary{
    grid-area: wizardSummary;
    width: 100%;
}
i.arrow {
    border: solid black;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    cursor: pointer;
}
.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.wizardPhaser {
    width: 100%;
}
.wizardInstruction {
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 25px;
}
div.stepElement {
    width: 100%;
    height: 60px;
    text-align: center;
}

.wizardSummaryContainer {
    width: 100%;
    display: grid;
}
.wizardSummaryHeading {
    width: 100%;
    text-align: center;
    margin: 10px 0 15px 0;
}
.wizardSummaryStepContainer {
    width: 100%;
    display: block;
    padding: 0 5px 0 5px;
}
.wizardSummaryStepHeading {
    background-color: #F8F8F8;
    width: 100%;
    text-align: left;
    margin: 4px 0 6px 0;
}
.wizardSummaryStep {
    width: 100%;
    background-color: #F8F8F8;
}
table.summaryFields {
    width: 100%;
}
td.groupingRow{
    font-weight: bold;
}
td.summaryFieldText {
    font-weight: lighter;
    font-size: 15px;
}
td.summaryFieldValue {
    font-weight: normal;
    font-size: 15px;
}
div.summaryText {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
    color: var(--xpool-button-text-color);
    text-align: center;
}
/*
label.fileButton{
    position: relative;
    appearance: none;
    padding: 0.05rem 1rem;
    text-transform: var(--xpool-button-text-transform);
    font-size: var(--xpool-button-font-size);
    font-weight: var(--xpool-button-font-weight);
    color: var(--xpool-button-text-color);
    height: var(--xpool-button-height);
    border: var(--xpool-button-border);
    border-radius: var(--xpool-button-border-radius);
    margin: 7px 7px 7px 0;
    background-color: var(--xpool-button-background-color);
    transition: background 0.3s, border 0.3s, color 0.3s;
    line-height: 30px;
    width: 120px;
    cursor: pointer;
}
label.fileButton:hover{
    background-color: var(--xpool-button-hover-background-color);
    border: var(--xpool-button-hover-border);
    color: var(--xpool-button-hover-text-color);
    font-weight: var(--xpool-button-hover-font-weight);
}*/
#wizardFile{
    display: none;
}
.wizardFormCard {
    max-width: 500px;
    min-width: 350px;
    width: 25%;

    margin: 5px 5px 5px 0px;
    padding: 0 7px 7px 7px;

    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: ghostwhite;
}
.wizardFormCardLarge {
    min-width: 500px;
    max-width: unset;
    width: calc(50% - 10px);
}
.wizardFormCardHeader {
    margin-bottom: 10px;
}
.submitRow {
    text-align: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}





h3.xpAccordionHeader {
    font-size:   19px;
    margin:  0;
    padding: 0;
}
.xpAccordionDisabledHeader {
    margin:  0;
    padding: 0;
    /*color: darkgray;*/
    color: gray;
    font-weight: 400;
    /*font-size:   19px;*/
}

h4.instanceUserRightsLabel {
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-top: 0px;
    margin-bottom: 5px;
    color: var(--xpool-header-text-color);
}
.instanceUserRightsCard {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 5px;
    padding-left:  7px;
    padding-right: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    /*background: ghostwhite;*/
}
.instanceUserRightsCardAdded {
    background-color: var(--xpool-form-card-new-background-color);
}
.instanceUserRightsCardRemoved {
    background-color: whitesmoke;
    font-size: var(--xpool-old-value-font-size);

    /*font-weight: var(--xpool-old-value-font-weight);*/
    color: gray;
    font-style: italic;
}
.instanceUserRightsBlock {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    border-radius: 0px;
    border-color: lightgray;
    border-style: solid;
    border-width: 0 1px 0 0;
    /*background: ghostwhite;*/
}

.adminUserRightsContainer{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
}
.adminUserRightsAssignButtons {
    text-align: center;
    vertical-align: center;
    width    : 193px;
    /*border: solid 1px gray;*/
    padding: 40px 0 0 0;
    margin-left: 7px
}
.adminUserRightsEntityList {
    min-width    : 300px;
    max-width    : 800px;
    width: calc(50% - 100px);
}




/*************************************
Menu overrides.
**************************************/
.ag-menu-option-shortcut {
    font-family: var(--xpool-font-family) !important;
}
.ag-menu-option-text {
    font-family: var(--xpool-font-family) !important;
}
.ag-tool-panel {
     border: none !important;
}
.ag-menu {
    max-height: 400px;
}
.ag-menu-column-select-wrapper {
    height: 350px;
}
.ag-theme-balham .ag-set-filter-list {
    max-height: 350px;
    height: unset;
}
.ag-filter-virtual-list-viewport {
    max-height: 350px;
}
.ag-popup-child {
    z-index: 10000;
}



/*************************************
Cell and header cell overrides.
**************************************/
.ag-cell, .ag-header-cell, .ag-header-group-cell {
    font-family: var(--xpool-font-family) !important;
    padding: 0px 8px 0px 8px !important;
    font-size: var(--xpool-grid-font-size);
    border: none !important;
}
.ag-cell, .ag-cell-inline-editing {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-body-text-color);
    border-bottom: var(--xpool-grid-row-border) !important;
}
.ag-cell:focus {
    outline: none;
}
.ag-header-cell-resize {
    /*z-index: auto !important;*/
}
.ag-theme-balham .ag-cell-data-changed {
    background: var(--xpool-grid-flash-background-color) !important;
}
.ag-header-cell, .ag-header-group-cell {
    /*fix SB styling*/
    color: var(--xpool-grid-header-text-color) !important;
    font-weight: var(--xpool-grid-header-font-weight) !important;
}
.ag-header-cell {
    border-bottom: var(--xpool-grid-header-border) !important;
}
.ag-header-group-cell {
    border-bottom: var(--xpool-grid-header-group-border) !important;
}
.ag-header-cell-moving, .ag-header-group-cell-moving {
    background-color: var(--xpool-grid-header-background-color) !important;
}





/*************************************
Header overrides.
**************************************/
.ag-header {
    border: none !important;
    background-color: var(--xpool-grid-header-background-color) !important;
}
.ag-header-row {
    border: none !important;
    background-color: var(--xpool-grid-header-background-color) !important;
}
.ag-header-container {
    border: none !important;
    background-color: white !important;
}


/*************************************
Row class overrides.
**************************************/
.ag-theme-balham .ag-row {
    transition: background-color 0.2s, color 0.2s !important;
    border: none !important;
    background-color: unset;
}
/*background: var(--xpool-grid-background-color) !important;*/
.ag-row-no-focus, .ag-row-focus {
    /*z-index: auto !important;*/ /* This did not work with inline editing and error bubbles since the z-index stacking context was npt created by the ag-row-inline-editing z-index setting */
}
.ag-row-hover {
    background: var(--xpool-grid-hover-background-color) !important;
    cursor: pointer;
}
.ag-theme-balham  .ag-row-selected {
    background: var(--xpool-grid-selected-background-color) !important;
}
.ag-theme-balham  .ag-row-editing {
    background: var(--xpool-grid-selected-background-color) !important;
}
.ag-theme-balham  .ag-cell-inline-editing {
    background: var(--xpool-grid-selected-background-color) !important;
    overflow: visible !important;
    border-bottom: var(--xpool-grid-row-border) !important;
}
.ag-theme-balham .ag-row-odd {
    background-color: unset;
}
.ag-theme-balham  .ag-row-even {
    background-color: unset;
}
.ag-details-row {
    padding-top: 10px !important;
    padding-left: 32px !important;
    padding-right: 0px !important;
    border-bottom: var(--xpool-grid-row-border) !important;
}


/*************************************
Body and viewport overrides.
**************************************/
.ag-root-wrapper {
    border: none !important;
    background-color: unset !important;
}
.ag-root {
    border: none !important;
}
.ag-body {
    border: none !important;
    border-bottom: var(--xpool-grid-row-border) !important;
}
.ag-overlay-wrapper {
    /*vertical-align: bottom !important;*/
    align-items: flex-end !important;
}
.ag-overlay-panel {
    padding-bottom: 20px !important;
}
.ag-body-viewport {
    border: none !important;
    /*
    background-color: var(--xpool-grid-background-color) !important;
    */
    /*overflow-x: hidden !important;*/
}
.ag-body-container {
    border: none !important;
    background-color: white !important;
    /*overflow-x: hidden !important;*/
}
.ag-center-cols-container {
    background-color: white !important;
}

/*************************************
Drag and drop overrides.
**************************************/
.ag-column-drop-horizontal, .ag-column-drop {
    border-bottom: var(--xpool-grid-row-border) !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: var(--xpool-grid-header-background-color) !important;
}
.ag-column-drop-cell {
    border: var(--xpool-grid-row-border) !important;
    background: rgba(0,0,0,0.2) !important;
}


/*************************************
Other overrides.
**************************************/

/* Needed to not clip last pixels from group header row content*/
.ag-group-value {
    padding-right: 2px;
}
.ag-side-bar, .ag-status-bar {
    border-right-style: none !important;
    border-bottom-style: none !important;
}
.ag-theme-balham {
    background-color: unset !important;
}

.ag-cell-value {
    overflow-x: hidden !important;
    overflow-y: visible !important;
}

.xpGridDetailContainer {
    padding: 10px 0px 20px 5px;
    background-color: white;
    border-bottom: var(--xpool-grid-row-border);
    cursor: default !important;
}
.xpGridDetailSelector {
    padding-top: 2px;
    vertical-align: top;
    display: inline-block;
    width: 30px;
    height: 100%;
}
.xpGridDetailLine {
    width: 22px;
    padding: 3px 10px 12px 10px;
}
.xpGridDetailLineFill {
    width: 100%;
    height: 100%;
    background-color: #E1E1E1;
    /*background-color: lightgray;*/
    border-radius: 2px;
}

.xpGridDetailIconSelected {
    overflow: hidden;
    padding: 3px 2px 2px 2px;
    margin-bottom: 5px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: var(--xpool-primary-color-strong);
    text-align: center;
    vertical-align: middle;
    z-index: 10;
}

.xpGridDetailIconSelected:hover {
}
.xpGridDetailIconNotSelected {
    /*background: var(--xpool-secondary-color-mid);*/
    background: var(--xpool-disabled-color);
}
.xpGridDetailIconNotSelected:hover {
    background: var(--xpool-primary-color-mid);
    cursor: pointer;
}

.xpGridDetailComponent {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 30px);
}
.xpGridMenuButton {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
}
.xpGridMenuButton:hover {
    opacity: 1;
}

.inputNumberInGrid {
    text-align: right;
    margin: 0 -5px 0 -5px;
    padding: 0 5px 0 5px;
}
.xpGridFormField {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin: 0 -5px 0 -5px;
    padding: 0 5px 0 5px;
}
.xpGridFormBeforeChangeField {
    margin-right: 10px;
}
.xpGridFormFieldValue {
}
.xpGridEnforceEditBackground {
    background-color: var(--xpool-form-field-background-color);
    border:  var(--xpool-form-field-border);
    border-radius: var(--xpool-form-field-border-radius);

}

.xpHeaderAlignMiddle {
    /*text-align: right;*/
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: nowrap;
}
.xpHeaderAlignRight {
    /*text-align: right;*/
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
}
.xpGroupHeaderAlignRight {
    /*text-align: right;*/
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding-right: 16px;
}
.highlightChangedFormFieldInGrid {
    background-color: rgba(250,255,152,0.5) !important;
}
.highlightErrorInFormFieldInGrid {
    background-color: rgba(255,190,170,0.5) !important;
    height: var(--xpool-line-height);
}

/*
************************************
Row class rule classes. All with ag-theme-balham in selector for precedence
*************************************
*/
.ag-theme-balham .xp-grid-disabled-row {
    background: lightgrey !important;
}
.ag-theme-balham .xp-grid-selected-order-row {
    background: var(--xpool-grid-selected-order-background-color) !important;
}
.ag-theme-balham .xp-grid-inBalance-order-row {
    background: var(--xpool-grid-inBalance-order-background-color);
    font-style: italic;
    color: #828282;
    /*color: black;*/
    /*font-weight: 200;*/
}
.ag-theme-balham .xp-grid-removed-row {
    background: var(--xpool-form-card-disabled-background-color);
    font-style: italic;
    color: #828282;
}
.ag-theme-balham .xp-grid-removed-row div {
    color       : inherit !important;
    font-style  : inherit !important;
    font-weight : inherit !important;
}

.ag-theme-balham .xp-grid-added-row {
    background: var(--xpool-form-card-new-background-color);
}
/*background: var(--xpool-grid-new-line-background-color);*/
/*
.ag-theme-balham .xp-grid-added-row div {
    background: var(--xpool-form-card-new-background-color);
}
*/
/*background: var(--xpool-grid-new-line-background-color);*/

.ag-theme-balham .xp-grid-neutral-row {
    background: none !important;
    font-style: unset !important;
    color: #8C8C8C;
    font-weight: 200;
}

.ag-theme-balham .xp-grid-executed-order-row {
    background: var(--xpool-grid-executed-order-background-color);
    font-style: italic;
    color: #8C8C8C;
    /*font-weight: 200;*/
}
.ag-theme-balham .xp-grid-executed-order-row div {
    color       : inherit !important;
    font-style  : inherit !important;
    font-weight : inherit !important;
}
.ag-theme-balham .xp-grid-rejected-order-row {
    background: var(--xpool-grid-rejected-order-background-color);
}
.ag-theme-balham .xp-grid-inactive-row {
    background: var(--xpool-grid-inactive-rowbackground-color);
}
.ag-theme-balham .xp-grid-onHold-order-row {
    background: var(--xpool-grid-onHold-order-background-color);
}
.ag-theme-balham .xp-grid-default-order-row {
    background: none !important;
}
.ag-theme-balham .xp-grid-no-background {
    background: none;
}

/*
************************************
Styling for cash pool accounting grid
*************************************
*/
.xp-grid-ingoing-outgoing-balance-row {
    border-bottom-style: solid !important;
    border-bottom-color: gray !important;
    border-bottom-width: 1px !important;
    font-weight: 600 !important;
}

/*
************************************
Styling for view dialog in dev mode for execution rights
*************************************
*/
.xpoolModalViewExecutionRights {
    width: 90% !important;
}


.changedRendererCell {
    background: var(--xpool-grid-flash-background-color);
}

.flashRendererCell {
    background: rgba(0,0,0,0);

    transition-duration: 1000ms;
    transition-property: background;
    transition-timing-function: ease-in-out;
}

.auditFilterSelect {
    min-width    : 150px;
    max-width    : 250px;
    width        : calc(20% - 20px);
}
.auditFilterDate {
    width: 120px;
}
.auditActionBaseColumn    {width: calc(33% - 18px);}
.auditActionContentColumn {width: calc(67% - 18px);}


.overallExposureHeader {
    padding: 5px 0 5px 0;
    margin: 0 20px 10px 20px;
    color: var(--xpool-body-text-color);
    border-top:    var(--xpool-grid-header-border);
    border-bottom: var(--xpool-grid-header-border);
    text-align: center;
}

.overallExposureContainer {
    padding: 0px 0px 0px 0px;
}
.overallExposureButtonsContainer {
    text-align: center;
}

.currencyList{
    width: 100%;
}
.currencySelect{
    width: 70%;
}
.currencyRow{
    width: 100%;
    display: flex;
}
.selectedAgreements {
    padding-left: 5px;
}
.agreementDataColumn {
    display: inline-block;;
    width: calc(50% - 5px);
    vertical-align: top;
}
.selectedCurrencies{
    text-align: left;
    margin-bottom: 4px;
    margin-right: 15px;
    margin-top: 4px;
    width: 60px;
    white-space: nowrap;
    overflow: visible;
    float: left;
}
.removeCurrencies{
    width: 9%;
    text-align: left;
    margin-bottom: 12px;
    float: left;
}
.halfFormContainer{
    width: 100%;
    display: block;
}
.inputText{
    font-size: smaller;
}
.halfForm{
    width: 49%;
}
.selectContainer {
    width: 60%;
}
.saveButton {
    display: block;
    width: 100%;
}
div.currencyPairContainer{
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.currencyPairLabel{
    text-align: center;
    height: 30px;
    width: 65px;
    padding: 6px;
    margin: 3px 5px 4px;
    background-color: #3f51b5;
    color: white;
    font-size: medium;
    font-weight: bold;
    vertical-align: middle;
    line-height: 22px;
}
.agreementFormRow {
    width: 100%;
    display: block;
    margin: 15px 10px;
}
.agreementFormRowGrid {
    display: grid;
    width: 100%;
    grid-template-columns: 75px 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-template-areas: "currencyPairHeading tenor period dispatchLevel dispatchPeriod"
                         "addSameCurrencyPair priceType gracePeriod openTime closeTime";
    align-items: start;
    justify-items: start;
}
.agreementFormRowGridFull {
    display: grid;
    width: 100%;
    grid-template-columns: 75px 3fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-template-areas: "currencyPairHeading tenor openTime closeTime"
                         "addSameCurrencyPair tenor openTime closeTime";
    align-items: start;
    justify-items: start;
}
.accountFormSweepTopUp {
    float: left;
}
.accountFormSweepTopUpRow {
    width: 99%;
    display: inline-block;
}
.agreementFormAddRemoveContainer{
    width: 99%;
    display: inline-block;
}
.agreementFormCell {
    float: left;
    margin-right: 10px;
}
.agreementFormCellFull {
    float: left;
    margin-right: 10px;
    height: 100%;
    width: 100%;
}
.firstFieldContainer{
    width: 100%;
    margin-top: 10px;
}
.shadow {  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */  /* Firefox 3.5 - 3.6 */
    box-shadow:         5px 3px 3px 1px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.currencyPairShadow {  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 2px 2px 1px #0000A0;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.submitRow {
    text-align: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}
.singleAdminColumn {
    min-width: 500px;
    display: block;
    box-sizing: border-box;
    margin-right: 0;
}
.formRow {
    width: 100%;
    display: inline-block;
}
.formCell {
    float: left;
    padding-right: 15px;
}
.separate {
    margin-left: 55px;
}
.baseCurrencyLabel {
    font-size: 18px;
    font-weight: normal;
    margin-top: 20px;
}
.wizardFormContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "wizardFormColumn1 wizardFormColumn2 wizardFormColumn3";
}
.wizardFormContainerRight {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "wizardFormColumn1 wizardFormColumn2 wizardFormColumn2";
}
.wizardFormColumn1 {
    display: block;
    padding: 10px;
    grid-area: wizardFormColumn1;
}
.wizardFormColumn2 {
    display: block;
    padding: 10px;
    grid-area: wizardFormColumn2;
}
.wizardFormColumn3 {
    display: block;
    padding: 10px;
    grid-area: wizardFormColumn3;
}


.integrationStatusContainer {
    width: 100%;
    /*padding: 2px;*/
}

.itemText {
    font-size: 3.5vw;
    font-weight: bold;
    width: 52%;
    vertical-align: top;
    /*height: 16%;*/
    /*margin: 8% 0;*/
    /*line-height: 0;*/
}

.headerText {
    padding: 5px;
    font-weight: var(--xpool-header-font-weight);
    /*font-size: 5vw;*/
    font-size: 1%;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
    color: var(--xpool-header-text-color);
}

.showEventButton {
    position: absolute;
    /*height: 30px !important;*/
    bottom: 0;
    left: 0;
}

.integrationStatusItem {
    display: inline-block;
    width: 100%;
    border: 1px solid slategray;
    border-radius: 5px;
    padding: 1%;
    margin-bottom: 1%;
    text-align: left;
    color: var(--xpool-body-text-color);
    background: var(--xpool-wizard-status-bar-background-color)
}
.menuStatusLight {
    display: inline-block;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    border-radius: 50%;
    /*border: 10px inset rgba(51,153,0,0.65);*/
    background: darkgray;
}
.menuStatusLightShortName {
    color: var(--xpool-body-text-color);
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: 4px;
    width: 21px;
    text-align: center;
}
.statusLight {
    display: inline-block;
    float: right;
    width: 16%;
    padding-top: 16%; /* 1:1 Aspect Ratio */
    border-radius: 50%;
    box-shadow:0px 0px 0px 2px var(--xpool-wizard-status-bar-background-color) inset;
    /*border: 10px inset rgba(51,153,0,0.65);*/
    vertical-align: middle;
    background: darkgray;
}
.statusLightOk {
    background: radial-gradient(white -80%, #2dc937);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightStarting {
    background: radial-gradient(white -80%, #9BEFA1);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightWarning {
    background: radial-gradient(white -80%, #E7BC16);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightError {
    background: radial-gradient(white -80%, #E77C16);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightDown {
    background: radial-gradient(white -80%, #fe5755);
    box-shadow:0px 0px 0px 2px dimgray inset;
}

.tooltip-inner {
    white-space: pre-wrap;
}

.marketSimulatorInactive {
    background: none;
}
.marketSimulatorPosted {
    background: none;
}
.marketSimulatorActive {
    background: none;
}
.marketSimulatorConfigItem {
    background: ghostwhite;
    border: lightskyblue solid 1px;
    border-radius: 8px;
    padding: 6px;
    position: relative;
    margin-bottom: 10px;
    min-height: 130px;
}

.bankHolidaysFilterButtonsGroup {
    min-width    : 400px;
    max-width    : 600px;
    width        : 30%;
}
.bankHolidaysFilterSelect {
    /*padding-right: 30px;*/
    min-width    : 80px;
    max-width    : 150px;
    width        : calc(13% - 20px);
}
.bankHolidaysFilterDate {
    min-width    : 100px;
    max-width    : 120px;
    width        : calc(10% - 20px);
}
.rbc-row-content {
    z-index: unset !important;
}
.bankHolidayIndicatorBase {
    font-size: var(--xpool-table-font-size);
    font-weight: 600;
    color: var(--xpool-body-text-color);
    /*
    line-height: 17px;
    height: 17px;
    */
    /*height: 17px;*/
    display: inline;
    /*
    position: absolute;
    top: 3px;
    left: 3px;
    */
    /*background-color: red;*/
    width: 40px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: default;
}
.bankHolidayIndicatorNoHolidays {
    background-color: rgb(31,31,31);
}
.bankHolidayIndicatorHolidays {
    background-color: var(--xpool-user-message-warning-background-color);
}
.bankHolidayIndicatorNoRunDate {
    background-color: var(--xpool-user-message-error-background-color);
}


.xpoolModal {
    position: absolute;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    opacity: 0;

    max-height: 100%;

    background: white;
    padding: 10px;
    border: 1px solid gray;
    width: 400px;
    min-width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
}

.xpoolModalConfirmWithComment {
    width: 500px;
}

.xpoolModalReport {
    width: 800px;
}

.xpoolModalLangEdit {
    width: 60%;
}
.xpoolModalAudit {
    width: 90%;
}

.xpoolModalFileReaderErrorMessage {
    width: 60%;
}
.xpoolModalGenerateUserMessage {
    width: 40%;
}
.xpoolModalGenerateUserMessageRow {
    width: 100%;
    padding-bottom: 12px;
}
.xpoolModalGenerateUserMessageInlineSelect {
    float: right;
    width: 30%;
    margin-top: 0;
}

.xpoolModalAfterOpen {
    opacity: 1;
}

.xpoolModalBeforeClose {
    opacity: 0;
}

.xpoolModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;
}

.xpoolModalOverlayAfterOpen {
    background-color: rgba(0, 0, 0, 0.5);
}

.xpoolModalOverlayBeforeClose {
    background-color: rgba(0, 0, 0, 0);
}

.xpoolModalButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.xpoolModalButtonsCenter{
    display:flex;
    justify-content:center;
    align-items:flex-end;
}
.xpoolModalHeader {
    padding-left: 5px;
    margin: 0;
    margin-bottom: 10px;
    color: var(--xpool-header-text-color);
    text-align: center;
    font-size: var(--xpool-modal-label-font-size);
    font-weight: var(--xpool-modal-label-font-weight);
}

.modalBodyHighestAlert {
    background-color: white;
    animation-name: highestAlertAnimation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes highestAlertAnimation {
    0% {
        background-color: white;
    }
    80% {
        background-color: #FFD6D7;
    }
    95% {
        background-color: rgba(141,5,5);
    }
    100% {
        background-color: white;
    }
}

.react-grid-layout {
}

.react-grid-item {
    /*overflow-y: auto !important;*/
    /*position: relative !important;*/
}

.react-resizable-handle {
    /*position: fixed !important;*/
}

/*
.react-draggable-placeholder {
    background-color: yellow;
}
*/
.react-grid-placeholder {
    background-color: yellow;
}

.react-resizable-hide {
    background-color: yellow;
}

.react-grid-item.react-grid-placeholder {
    background: var(--xpool-secondary-color-mid) !important;
}

.dashboardContainer {
    width: 100%;
}
.dashboardHolder {
    height: 100%;
    overflow-y: auto;
    border: solid 1px whitesmoke;
    border-radius: 7px;
}
.dashBoardComponentContent {
    height: calc(100% - 42px);
}
.dashboardPicker {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-top: -10px;
    height: 40px;
    padding: 4px;
    background-color: var(--xpool-grid-header-background-color);
    color: white;
    border-radius: 5px;
}
.dashboardCanvas{
    width: calc(100% + 20px);
    min-height: 50px;
    margin-left: -10px;
    padding-top: 5px;
}

.layout {
    background: white;
}

.dashboardIcon {
    position: relative;
    display: inline-block;
    float: left;
    overflow: hidden;
    padding: 0;
    margin-top: 0px;
    margin-left: 5px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: var(--xpool-primary-color-strong);
    /*border: var(--xpool-button-border);*/
    text-align: center;
    vertical-align: middle;
    z-index: 10;
}
.dashboardIcon:first-child {
    margin-left: 0px;
}

.dashboardIcon:hover {
    /*border: var(--xpool-button-hover-border);*/
    background: var(--xpool-primary-color-mid);
    cursor: pointer;
}
.dashboardIconInComponent {
    background: var(--xpool-primary-color-strong);
    width:  22px;
    height: 22px;
    padding: 2px 2px 2px 2px;
}
.dashboardIconInComponent:hover {
    background: var(--xpool-primary-color-mid);
    /*border: var(--xpool-button-border);*/
}

.center-div {
    display: flex;
    margin: 0 auto;
    padding-top: 5px;
    padding-right: 0px;
    width: 32px;
    height: 32px;
}
.icon-i {
    margin: auto; /* Important */
    padding-right: 0px;
    text-align: center;
}


.dashBoardComponentHeader {
    position: sticky;
    z-index: 1;
    top: 0;
    display: inline-block;
    width: 100%;
    border: none;
    border-radius: 6px 6px 0 0;
    padding: 5px;
    margin-bottom: 2px;
    text-align: left;
    background-image: linear-gradient(to top, RGB(0,0,0,0) 0%, var(--xpool-secondary-background-color)); /* Standard syntax (must be last) */
}
.dashBoardComponentHeaderInput {
    float: left;
    resize: none;
    font-weight: var(--xpool-header-font-weight);
    font-size: 20px;
    text-align: center;
    width: 100%;
    height: 25px;
    line-height: 25px;
    color: var(--xpool-header-text-color);
    overflow: no-display;
}
.dashBoardComponentInputWrapper {
    padding-right: 55px;
    padding-left: 5px;
}
.dashBoardComponentHeaderText {
    font-family: var(--xpool-header-font-family);
    font-weight: var(--xpool-header-font-weight);
    font-size: 20px;
    text-align: center;
    padding-right: 35px;
    padding-left:  35px;
    width: 100%;
    height: 25px;
    line-height: 25px;
    margin-bottom: 5px;
    color: var(--xpool-header-text-color);
    cursor: pointer;
    overflow: no-display;
    white-space: nowrap
}
.closeDashboardComponentButton {
    float: right;
    opacity: 0.3;
    /*display: inline-block;*/
    text-align: right;
    margin-left: 2px;
    margin-top: 2px;
    height: 20px;
    padding: 0;
    vertical-align: middle;
    border: none;
}

.closeDashboardComponentButton:hover {
    border-color: darkslategray;
    cursor: pointer;
    opacity: 0.5;
}
.closeDashboardComponentButton:active {
    border-color: darkslategray;
    cursor: pointer;
    opacity: 1;
}
.closeDashboardComponentButtonInactive {
    opacity: 0.1;
    /*display: inline-block;*/
}

.closeDashboardComponentButtonInactive:hover {
    cursor: not-allowed;
    opacity: 0.1;
}
.closeDashboardComponentButtonInactive:active {
    cursor: not-allowed;
    opacity: 0.1;
}



.react-grid-item {
    overflow: hidden;
    /*background-image: linear-gradient(to bottom, RGB(0,0,0,0) 70%, var(--xpool-grid-hover-background-color)); !* Standard syntax (must be last) *!*/
    background-image: linear-gradient(to bottom, RGB(0,0,0,0) 70%, ghostwhite); /* Standard syntax (must be last) */
    border-radius: 7px;
}
.dashboard-dummy-component{
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 10px;
    border-radius: 7px 7px 0 0;
    background-image: linear-gradient(to bottom, RGB(0,0,0,0) 70%, var(--xpool-grid-hover-background-color));
}

.reportFilterSelect {
    min-width    : 150px;
    max-width    : 250px;
    width        : calc(20% - 20px);
}
.reportFilterDate {
    min-width    : 100px;
    max-width    : 120px;
    width        : calc(10% - 20px);
}
.reportFilterCurrency {
    min-width    : 50px;
    max-width    : 150px;
    width        : calc(20% - 20px);
}
.reportFilterViewReportGroup {
    min-width: 300px;
    max-width: 500px;
    width: 30%;
}
.reportFilterReportType {
    float        : right;
    min-width    : 150px;
    max-width    : 250px;
    width        : calc(100% - 150px);
}


.reportModalLargeHeader {
    font-size: 40px;
}

.reportModalHeader {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 15px;
    color: var(--xpool-header-text-color);
    text-align: center;
}

.reportModalHead {
    width: 100%;
    height: 25px;
    vertical-align: bottom;
    padding-bottom: 0px;
    padding-right: 20px;
    padding-left : 20px;
    margin-bottom: -5px;
    /*width: calc(100% + 30px);*/
}
.reportModalConfig {
    display      : inline-block;
    margin-right : 10px;
}
.reportModalCloseButton {
    display      : inline-block;
    float        : right;
    margin-top   : -18px;
    margin-left  : 3px;
}


.noRunDateSelect {
    display: inline-block;
    width: 30%;
    max-width: 250px;
    min-width: 150px;
    margin-top: 0;
    padding-right: 10px;
}
.noRunDateTableLabel {
    /*border-bottom: var(--xpool-grid-row-border);*/
    margin-bottom: 5px;
    min-width: 400px;
}
.rbc-time-view .rbc-allday-cell {
    max-height: 300px;
    overflow: auto;
}

/*
Below all global css variables are declared. These will be valid in all CSS-code in the application.
The declarations are replaced in run-time when changing styling through setStyling thunk.
*/

:root {
    /*------ primary / secondary colors ---------------------------------*/
    /*-------------------------------------------------------------------*/
    /*--xpool-primary-color:           #6aa5c1;*/
    --xpool-primary-color-x-strong:  #6aa5c1;
    --xpool-primary-color-strong:    #6aa5c1;
    --xpool-primary-color-mid:       #b0cfde;
    --xpool-primary-color-weak:      #d7e7ee;
    --xpool-primary-color-x-weak:    #d7e7ee;

    --xpool-secondary-color-strong:  #6aa5c1;
    --xpool-secondary-color-mid:     rgb(106 165 193);
    --xpool-secondary-color-weak:    #6aa5c1;

    --xpool-primary-background-color:   lightgray;
    --xpool-secondary-background-color: lightgray;

    --xpool-disabled-color:          lightgray;


    /*------ buttons styling --------------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-button-height:                  25px;
    --xpool-button-border-radius:           4px;

    --xpool-button-font-weight:             300;
    --xpool-button-font-size:               12px;
    --xpool-button-text-color:              black;

    --xpool-button-background-color:          #A8D5EA;
    --xpool-button-hover-background-color:    #9ECADD;
    --xpool-button-active-background-color:   #8FB6C8;
    --xpool-button-hover-text-color:          var(--xpool-button-text-color);

    --xpool-button-in-table-font-size:      12px;


    /*------ form styling -----------------------------------------------*/
    /*-------------------------------------------------------------------*/

    /*--xpool-form-error-background-color:    rgba(255,202,192,0.7);*/
    --xpool-form-field-background-color:    rgba(255,255,255,0.8);
    --xpool-form-disabled-background-color: rgba(255,255,255,0);

    --xpool-form-card-update-background-color:   #FFFFF3;
    --xpool-form-updated-field-background-color: rgba(250,255,152,0.5);

    --xpool-form-card-new-background-color:      #E5FDDD;
    --xpool-form-card-disabled-background-color: #FFE5E6;

    --xpool-form-input-text-color:          #434343;

    --xpool-label-font-weight:              300;
    --xpool-label-font-size:                14px;
    --xpool-label-text-color:               #434343;
    --xpool-label-height:                   18px;

    --xpool-field-value-font-weight:        400;

    --xpool-form-before-change-text-color:  #DD5400;
    --xpool-old-value-font-weight:          400;
    --xpool-old-value-font-size:            12px;

    --xpool-form-error-text-color:          #434343;

    --xpool-form-field-border:              1px solid #bcbcbc;
    --xpool-form-field-border-radius:       4px;

    /*------ xp grid styling --------------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-grid-header-background-color:   #D2EEFB;

    --xpool-grid-highlight-color-base:      106,165,193;
    --xpool-grid-flash-background-color:    rgba(var(--xpool-grid-highlight-color-base),0.2);
    /*--xpool-grid-hover-background-color:    rgba(var(--xpool-secondary-color-strong), 0.08);*/
    --xpool-grid-hover-background-color:    rgba(var(--xpool-grid-highlight-color-base), 0.08);
    --xpool-grid-selected-background-color: rgba(var(--xpool-grid-highlight-color-base),0.2);
    --xpool-grid-new-line-background-color: rgba(var(--xpool-grid-highlight-color-base),0.4);

    --xpool-grid-selected-order-background-color  : rgba(194,219,168,0.5);

    --xpool-grid-inBalance-order-background-color : #F8F8F8;
    --xpool-grid-executed-order-background-color  : #F8F8F8;
    --xpool-grid-rejected-order-background-color  : #FFCAC0;
    --xpool-grid-onHold-order-background-color    : #FDFFDB;
    --xpool-grid-inactive-rowbackground-color     : #F1F1F1;

    --xpool-grid-header-font-weight:        400;
    --xpool-grid-font-size:                 15px;
    --xpool-grid-header-text-color:         black;

    --xpool-grid-row-border:                1px solid #ebe7e2;
    --xpool-grid-header-border:             2px solid lightslategray;
    --xpool-grid-header-group-border:       1px solid RGB(191, 197, 202);

    /*--xpool-grid-background-color:          none;*/
    /*--xpool-grid-rows-background-color:      white;*/
    /*--xpool-grid-dropped-background-color:  rgba(255,255,255,0.3);*/



    /*------ user message styling ---------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-user-message-ok-background-color:     #CEFFD2;
    --xpool-user-message-info-background-color:   #E2FFF7;
    --xpool-user-message-warning-background-color:#FDFFDB;
    --xpool-user-message-error-background-color:  #FFCAC0;

    --xpool-user-message-grid-ok-background-color:     rgba(149,255,158,0.5);
    --xpool-user-message-grid-info-background-color:   rgba(200,255,240,0.5);
    --xpool-user-message-grid-warning-background-color:rgba(252,255,184,0.5);
    --xpool-user-message-grid-error-background-color:  rgba(255,148,127,0.5);

    --xpool-user-message-text-color:        #434343;

    --userMessageSlideHeight: -33px;

    /*------ run state styling ------------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-client-run-status-fetching-balances-background-color:     rgba(199,255,240,0.5);
    --xpool-client-run-status-waiting-for-execution-background-color: rgba(179,255,185,0.5);
    --xpool-client-run-status-executing-background-color:             rgba(99 ,248,111,0.5);
    --xpool-client-run-status-run-ok-background-color:                rgba(0  ,212,16 ,0.5);
    --xpool-client-run-status-warning-background-color:               rgba(252,255,184,0.5);
    --xpool-client-run-status-failed-background-color:                rgba(255,148,127,0.5);
    --xpool-client-run-status-disabled-background-color:              rgba(213,213,213,0.5);


    /*------ general typography -----------------------------------------*/
    /*-------------------------------------------------------------------*/
    --xpool-font-family:                    Lato, sans-serif;
    --xpool-header-font-family:             Lato, sans-serif;

    --xpool-standard-font-weight:           300;
    --xpool-standard-font-size:             16px;
    --xpool-body-text-color:                #434343;

    --xpool-header-font-weight:             400;
    --xpool-header-text-color:              #6AA5C1;

    --xpool-bold-font-weight:               600;

    --xpool-modal-label-font-weight:        500;
    --xpool-modal-label-font-size:          20px;

    --xpool-tooltip-font-weight:            100;
    --xpool-tooltip-font-size:              12px;

    --xpool-table-font-size:                14px;

    --xpool-black-text-color:               black;


    /*------ other ------------------------------------------------------*/
    /*-------------------------------------------------------------------*/
    --xpool-line-height:                    25px;

    --xpool-focus-box-shadow:               0 0 10px 2px rgba(0, 0, 0, 0.2);

}

/*$color-headers: #434343;*/

/* Needed for not getting an ugly blue ring around tooltips in graphs */
:focus-visible {
    outline: 0 !important;
}

.xpWarningBanner {
    display: block;
    width: 100%;
    border: 1px solid slategray;
    border-radius: 0px;
    padding: 5px;
    margin-bottom: 0px;
    text-align: center;
    color: var(--xpool-user-message-text-color);
    background: var(--xpool-user-message-warning-background-color);
}

.xpoolToolTip {
    white-space: pre-wrap;
    z-index: 2000 !important;
    background: lightgray;
    opacity: 0.9 !important;
}











/*
.xpResizableContainer {
    border-bottom: 2px solid var(--xpool-primary-color-x-weak);
}
.xpResizableContainerDisabled {
    border-bottom: none;
}
.xpResizableContainerManaged {
    border-bottom: 2px solid var(--xpool-primary-color-weak);
}
*/




.xpResizableHandle {
    background: var(--xpool-primary-color-x-weak);
}
.xpResizableHandle:hover {
    background: var(--xpool-primary-color-weak);
}
.xpResizableHandle:active {
    background: var(--xpool-primary-color-mid);
}
.xpResizableHandleDisabled {
    background: var(--xpool-primary-color-x-weak);
}
.xpResizableHandleManaged {
    background: var(--xpool-primary-color-weak);
}
.xpResizableHandleManaged:hover {
    background: var(--xpool-primary-color-mid);
}
.xpResizableHandleManaged:active {
    background: var(--xpool-primary-color-mid);
}
.xpVerticalHandle {
    width: 12px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-x-weak) 5px, var(--xpool-primary-color-x-weak) 7px, rgba(0,0,0,0) 7px);
}
.xpVerticalHandle:hover {
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-weak) 5px, var(--xpool-primary-color-weak) 7px, rgba(0,0,0,0) 7px);
}
.xpVerticalHandle:active {
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-mid) 5px, var(--xpool-primary-color-mid) 7px, rgba(0,0,0,0) 7px);
}
.xpVerticalHandleDisabled {
    width: 12px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-x-weak) 5px, var(--xpool-primary-color-x-weak) 7px, rgba(0,0,0,0) 7px);
}
/*
.xpVerticalHandle {
    border-left: 5px solid rgba(255, 255, 255, 1);
    border-right: 5px solid rgba(255, 255, 255, 1);
}
*/
.xpHorizontalHandle {
    border-top: 5px solid rgba(255, 255, 255, 1);
    border-bottom: 5px solid rgba(255, 255, 255, 1);
}


/*MT20180322: Added this to override user agent stylesheet*/
ul, menu, dir {
    -webkit-padding-start: 0px;
}


/* Override for bootstrap menu component*/
.navbar {
    border-radius: 0px;
    border: none;
    background-color: rgb(34,34,34) !important;
    padding: 0px 0 0px 0;
}
.nav-link {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}
.navbar-brand {
    padding-top: 7px !important;
    padding-right: 0px !important;
}
.dropdown-item {
    font-weight: var(--xpool-standard-font-weight)  !important;
    color: var(--xpool-body-text-color);
    font-size: var(--xpool-label-font-size)  !important;
    padding-left:  20px !important;
    padding-right: 20px !important;
}
.dropdown-item.disabled {
    color: #adb5bd !important;
}

/*.navbar .nav li.dropdown > .dropdown-toggle .caret {*/
/*.dropdown-menu .dropdown-toggle .caret {*/
.dropdown-menu .dropdown-toggle::after {
    border-bottom-color: #adb5bd !important;
    border-top-color: #adb5bd !important;
}

.subMenuDropDown {
    color: var(--xpool-body-text-color) !important;
    font-size: var(--xpool-label-font-size)  !important;
}
.dropdown-menu .dropdown-menu { /* Needed to get sub-menu for numbering formats to stay in right place */
    right: -50px !important;
    left: auto !important;
}

.dropdown-menu .dropdown-toggle {
    padding: 0 !important;
    /*padding: 4px 20px 4px 20px !important;*/
}

.clientSelectorMenuList {
    max-height: 500px;
    overflow: auto;
}
.clientSelectorSearchField {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-body-text-color);
    font-size: var(--xpool-label-font-size);
    padding-left: 20px;
    padding-right: 20px;
}


/*
.dropdown-toggle {
    max-width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
}
*/

.navbar-brand { /*Hight of top menu bar */
    height: 45px !important;
    padding: 10px 15px 15px 10px;
}

ul[aria-labelledby=client-nav-dropdown] { /* MT: Needed to get client-menu scrollable when many clients in system */
    max-height: 500px;
    overflow: scroll;
}

.nav > li > a {
    padding-left:  10px;
    padding-right: 5px;
}
.nav:last-child{
    padding-right: 10px;
}

.refreshIcon {
    display: inline-block !important;
    width: 22px;
    vertical-align: middle;
    padding-left: 0px !important;
    padding-bottom: 5px;
    margin-right: 5px;
}
.refreshIcon:hover {
    cursor: pointer;
}
.refreshIconNavItem {
    padding: 13px 15px 13px 5px;
}

.NFCLogo {
    display: inline-block !important;
    width: 70px;
    visibility: visible;
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        padding-left: 0;
    }
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    padding-left: 15px;
}

/*
@media (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px;
    }
    .dropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        !*margin: 7.5px -15px;*!
    margin: 7.5px 50px 7.5px -15px
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
!* since 3.1.0 *!
               .navbar-collapse.collapse.in {
                   display: block!important;
               }
    .collapsing {
        overflow: hidden!important;
    }
}
*/


/*
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}
*/







.adminMain {
    display: flex;
    flex-wrap: wrap;
    /*border: 1px solid red;*/
}
.adminSelector {
    flex: 20% 1;
    max-width: 20rem;
    min-width: 16rem;
    padding-right: 25px;
    /*border: 1px solid pink;*/
}
.welcomeScreen {
    width: 100%;
    max-width: 40rem;
    min-width: 10rem;
    margin: auto;
    text-align: center;
    /*border: 1px solid pink;*/
}
.adminData2col{
    display: flex;
    flex: 75% 1;
    flex-wrap: wrap;
    /*border: 1px solid blue;*/
}
.adminData3col{
    display: flex;
    flex: 100% 1;
    flex-wrap: wrap;
    /*border: 1px solid blue;*/
}
.adminData1col{
    display: flex;
    flex: 25% 1;
    flex-wrap: wrap;
    /*border: 1px solid blue;*/
}
.adminDataColumn{
    flex: 50% 1;
    max-width: 30rem;
    min-width: 16rem;
    /*border: 1px solid yellow;*/
    padding-right: 30px;
    flex-shrink: 0;
}
.adminDataColumn2{
    flex: 33% 1;
    max-width: 20rem;
    min-width: 16rem;
    /*border: 1px solid yellow;*/
    padding-right: 30px;
}

body {
    font-family: var(--xpool-font-family);
    background-color: white;
    color: var(--xpool-body-text-color);
    text-align: left;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-standard-font-weight);
    height: 100%;
}

.oxyGenApp {
    /*
    min-height: 100%;
    */
    height: 100%;
}

html {
    font-size: var(--xpool-standard-font-size);
    height: 100%;
    width: 100%;
    margin: 0;
}

.clickToExpand {
    font-family: var(--xpool-font-family);
    font-size: 13px;
    font-weight: 300;
    color: dimgray;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 5px;
}

.standardTextColor {
    color: var(--xpool-body-text-color);
}

a, ul, li, p {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-body-text-color);
}

.clientItem, dropdown-menu {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-label-text-color);
}

/*
MT20210127: I removed this because I cannot understand what it does. I did not see any impact on the menu when I removed it.
@media (max-width: 767px)
<style>…</style>
          .navbar-nav .open .dropdown-menu > li > a {
              line-height: 20px;
          }
*/

.content {
    padding: 15px;
    margin-top: -5px;
    height: 100%;
}
.contentUserApp {
    /*margin-top: -5px;*/
    height: 100%;
}

h1,h2,h3,h4,h5 {
    font-family: var(--xpool-header-font-family);
}

h1 {
    font-weight: var(--xpool-header-font-weight);
}
h2 {
    padding-left: 5px;
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-bottom: 10px;
    color: var(--xpool-header-text-color);
    text-align: center;
}
h3 {
    padding: 5px 5px 0px 5px;
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
    color: var(--xpool-header-text-color);
    font-size: var(--xpool-modal-label-font-size);
}

h4 {
    padding: 5px;
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
    color: var(--xpool-header-text-color);
}

h5 {
    padding: 5px;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-header-font-weight);
    color: var(--xpool-header-text-color);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
}

.content li {
    margin-bottom: 10px;
}

.xpSliderContainer {
    width: 100%;
    height: 40px;
}
.xpChartSliderContainer {
    width: 100%;
    padding: 0 35px 0 70px;
    height: 20px;
}

.languageItem {
    margin-left: 15px;
}

.flagImage {
    width: 21px;
    vertical-align: middle;
    background-color: white;
}

.flagImageInMenu {
    border: solid 1px lightgray;
}

.smallFlagIcon {
    width: 45px;
    vertical-align: middle;
    /*background-color: white;*/
}

.userImage {
    width: 18px;
    margin-top: -5px;
    vertical-align: middle;
}

.spinner {
    width: 70px;
}
.textSpinner {
    width: 36px;
}
.loadingSpinner {
    width: 50px;
    margin-left: -18px;
    margin-right: -5px;
    margin-top: -2px;
}
.smallTableSpinner {
    width: 25px;
}
.smallTableSpinnerPosAbsolute {
    position: absolute;
    left: -4px;
    top:  -3px;
    width: 32px;
}

.construction {
    width: 500px;
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid #434343;
}

.floatingDiv {
    position: absolute;
    /*-webkit-transform: translate3d(0,0,0);*/
    left: 450px;
    top:  300px;
    background: white;
    padding: 0px 7px 7px 7px;
    border: 1px solid gray;
    width: 350px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    /*z-index: 10;*/
}
.draggableHandle:hover {
    cursor: pointer;
}

img.buttonIcon {
    opacity: 0.5;
    height: 18px;
    border: none;
    padding: 0px 0 2px 0;
}
img.buttonIconInline {
    display: inline-block;
}

img.buttonIcon:hover {
    /*border-color: darkslategray;*/
    cursor: pointer;
    opacity: 0.8;
}
img.buttonIcon:active {
    /*border-color: darkslategray;*/
    cursor: pointer;
    opacity: 1;
}
img.buttonIconDisabled:hover {
    opacity: 0.5;
    cursor: not-allowed;
}
img.buttonIconDisabled:active {
    opacity: 0.5;
    cursor: not-allowed;
}
.xpChartToolTipContainer {
    /*background: ghostwhite;*/
    background: rgba(248, 248, 255, 0.8);
    border: solid 1px lightgray;
    padding: 5px;
    margin: 15px;
    /*position: absolute;*/
}
.xpMiniChartToolTipContainer {
    /*background: ghostwhite;*/
    background: rgba(248, 248, 255, 0.8);
    font-size: 10px;
    /*border: solid 1px lightgray;*/
    /*padding: 5px;*/
    /*margin: 15px;*/
    /*position: absolute;*/
}
.xpChartActiveDot {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    opacity: 0.6;
    border: none;
    border-radius: 12px;
}
.xpChartActiveDotInner {
    /*margin: 3px;*/
    position: absolute;
    top: 5px;
    left: 5px;
    height: 14px;
    width: 14px;
    opacity: 1;
    background: none;
    border: 2px solid ghostwhite;
    border-radius: 7px;
}
.xpChartActiveDotArrowBase {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
}
.xpChartActiveDotArrowUp {
    top: 5px;
}
.xpChartActiveDotArrowDown {
    top: -5px;
}
.xpTabsHeader {
    padding: 0px 10px;
}



/*.adminEntityCard {
    width: calc(100% - 30px);
    margin-top: 10px;
    margin-left: 30px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    padding-top: 5px;
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: ghostwhite;
}*/


.xpAdminFormEntityCard {
    width: 100%;
    margin-top: 10px;
    padding: 7px;
    /*
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    padding-top: px;
    */
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: var(--xpool-primary-background-color);
}
.xpAdminFormEntityCardNewStyling {
    width: 100%;
    /*margin-top: 10px;*/
    padding: 7px;
    border: none;
    background: var(--xpool-primary-background-color);
}
.xpAdminFormEntityCardIndent{
    width: calc(100% - 30px);
    margin-left: 30px;

}
.adminEntityCardEdit {
    background-color: var(--xpool-form-card-update-background-color);
    /*background: #E7FFFF;*/
}
.adminEntityCardNew {
    background-color: var(--xpool-form-card-new-background-color) !important;
    padding-bottom: 7px;
    /*background: #E7FFFF;*/
}
.adminEntityCardDisabled {
    background-color: var(--xpool-form-card-disabled-background-color) !important;
    /*background: #E7FFFF;*/
}
.ownedByParentRunConfig {
    /*background-color: rgba(179,255,185,0.5);*/
    background-color: rgba(230,230,230,0.5);
}
.formCard {
    /*
    width: calc(100%-30px);
    */
    margin-top: 5px;
    margin-left: 0px;
    margin-bottom: 5px;
    padding-left:  7px;
    padding-right: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: ghostwhite;
}
.adminEntityCardSubForm {
    padding-top:   0px;
    padding-right: 0px;
    margin-top:   -0px;
    margin-left:  0px;
    padding-left: 0px;
    border-style: none;
    background: none;
    width: 100%;
}
.adminEntityCard1Col {max-width: 400px;}
.adminEntityCard2Col {max-width: 800px;}
.adminEntityCard3Col {max-width: 1200px;}

.adminFormContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    grid-column-gap: 30px;
    column-gap: 30px;
    padding-left: 5px;
    padding-right: 5px;
    /*width: calc(100% + 30px);*/
}

h3.userPrefsCardHeader {
    margin-bottom: 10px;
    padding-bottom: 5px;
    text-align: center;
}
.userPrefsCard {
    margin-bottom: 30px;
}

.formContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}
.adminColumn {
    min-width    : 340px;
    max-width    : 700px;
    flex: 1 1 auto;
    width: 10%; /* This is a bit strange, but together with the flex setting it works for all column numbers. */
}
.admin1Column {}
.admin2Column {}
.admin3Column {}
.admin4Column {}

.ormFormButtons {
    padding-bottom: 0px;
}

.entitySubItemsHeader {
    width: 100%;
    padding-left:  7px;
    padding-right: 0px;
    border-radius: 0px;
    border-color: lightgray;
    border-bottom-style: none;
    border-width: 1px;
    background: ghostwhite;
    margin-bottom: -0px;
}
.entitySubItemsHeaderNoBackground {
    background: none;
}
.entitySubItemsHeaderText {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top:    calc( var(--xpool-button-height) - 10px) !important;
    padding-bottom:  2px !important;
}
.formHeaderEditNew {
    border-bottom: solid lightgray 1px;
    margin-bottom: 6px;
    padding-top: 0px;
    padding-bottom: 9px;
}

.entitySubItemsContainer {
    background: white;
    /*
    padding-bottom: 7px;
    */
    /*
    margin-bottom: 7px;
    */
}
.entitySubItemsContainerWithTopBorder {
    border-radius: 0px;
    border-color: lightgray;
    border-top-style: solid;
    border-width: 1px;
    margin-top: 7px;
}

.entitySubItemsList {
    /*margin-top: -5px;*/
    margin-top: 0px;
    background: white;
}
/*
.adminColumn:first-child {padding-left : 0px}
.adminColumn:last-child  {padding-right: 0px}
*/

.dateTimeFormSection {
    padding-top: 2px;
    padding-bottom: 10px;
}

.field {
    position: relative;
    display: block;
    margin-bottom: 5px;
}

.tableInForm {
    margin-bottom: 10px;
}

.formFieldFlex {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 10px;
}
.formFieldFlex:last-child {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2px;
}

.searchField {
    width: calc(100% - 24px);
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
}
.tableField {
    position: relative;
    display: block;
    margin: 0;
}

.inlineFormField {
    display: inline-block;
    padding-right: 10px;
}
.inlineFormField:last-child {
    display: inline-block;
    padding-right: 0px;
}

.formFieldsGroup {
    padding: 0px  5px  0px  4px;
    margin:  0px -6px 0px -5px;

    border: 1px solid rgba(0,0,0,0);
    border-radius: 5px;

    transition-duration: 250ms;
    transition-property: border;
    transition-timing-function: ease-in-out;
}
.formFieldsGroupExpanded {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-color: lightgrey;
}

.formFieldsGroupController {
    display: inline-block;
    width: calc(100% - 23px);
    /*padding-bottom: 5px;*/
}
.formFieldsGroupRemoveButton {
    float: right;
    margin-top: 2px;
    margin-right: 0px;
    /*padding-bottom: 5px;*/
}
.formFieldsGroupContent {
    padding: 0px 7px 0 0;
}
.formFieldsGroupContentStyle {
    transition-duration: 300ms;
    transition-property: height;
    transition-timing-function: ease-out;
    padding-left: 2px;
    /*overflow: hidden;*/
}

.runConfigurationTypeList {
    border-bottom: solid 1px lightgray;
    margin-bottom: 5px;
}

.formFieldsGroupContainerCloseTransition {
    /*height: 0;*/
    /*padding: 0 !important;*/
    /*border: 0;*/
    /*margin: 0;*/
    transition: height 0.3s ease-out, padding 0.3s ease-out, margin 0.3s ease-out, border 0.3s ease-out;
    overflow: hidden;
}

.inlineCheckBox {
    position: relative;
    display: inline-block;
    min-width: 100px;
}
.inlineCheckBoxWeekdays {
    display: inline-block;
    /*min-width: 58px;*/
}

.highlightChangedFormField {
    background-color: var(--xpool-form-updated-field-background-color);
    /*color:            var(--xpool-form-before-change-text-color);*/
    /*border: 1px solid slategray;*/
    border-radius: 0px;
}

.removedValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    color: gray;
}
.removedNumberValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    color: gray;
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
}
.addedValue {
    color: blue;
    font-size: var(--xpool-table-font-size);
}
.addedNumberValue {
    color: blue;
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
    font-size: var(--xpool-table-font-size);
}
.modifiedValue {
    font-size  : var(--xpool-table-font-size);
    line-height: var(--xpool-line-height);
    color      : var(--xpool-form-before-change-text-color);
}
.noChangeValue {
    font-size: var(--xpool-table-font-size);
    line-height: var(--xpool-line-height);
}
.modifiedNumberValue {
    text-align: right;
    padding-right: 7px;
    padding-left: 0px;
    line-height: var(--xpool-line-height);
    font-size: var(--xpool-table-font-size);
}
.beforeValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    /*color: var(--xpool-header-text-color);*/
    color:            var(--xpool-form-before-change-text-color);
    margin-left: 5px;
}
.beforeNumberValue {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    /*color: var(--xpool-header-text-color);*/
    color:            var(--xpool-form-before-change-text-color);
    text-align: right;
    padding-right: 7px;
    padding-left: 0px;
    margin-top: 5px;
}
.beforeCheckboxValue {
    border: solid 1px var(--xpool-form-before-change-text-color);
    border-radius: 2px;
    height: 18px;
    padding-top: 2px;
    transform: scale(0.8);
}
.noChangeCheckboxValue {
    margin: 6px 0 0 0;
}
.removedCheckboxValue {
    height: 18px;
    margin-top: 2px;
    transform: scale(0.8);
}

.checkBoxFieldInTable {
    text-align: center;
    /*padding: 5px 0 0 0;*/
}
/*
td input[type="checkbox"] {
    margin: 0 0 0 0;
}
*/

/*.oldValue {
    position: absolute;
    top: 18px;
    left: 10px;
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-old-value-text-color);
    !*color: #313131;*!
    margin: 0 0 0 0;
    padding: 4px 0 0 5px;
}*/

.oldValueField {
    position: absolute;
    top: -10px;
    left: 50px;
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-header-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
}
.oldValueFieldInGrid {
    position: absolute;
    top: -2px;
    left: 2px;
    /*color: #313131;*/
    margin: 0 0 0 -5px;
    padding: 0 0 0 8px;
}
.oldValueFieldInTable {
    position: absolute;
}
.oldValueSpan {
    font-size: var(--xpool-old-value-font-size);
    font-weight: var(--xpool-old-value-font-weight);
    /*color: var(--xpool-header-text-color);*/
    color:            var(--xpool-form-before-change-text-color);
    padding-left: 2px;
    /*margin-left: 5px;*/
    display: inline-block;
}

/*
.errors {
    position: absolute;
    font-size: var(--xpool-label-font-size);
    color: var(--xpool-form-error-text-color);
    !*margin-top: -5px;*!
    margin-top: -3px;
    animation: slide-down 0.3s backwards ease-in-out;
    font-weight: var(--xpool-standard-font-weight);
    background: var(--xpool-form-error-background-color);
    border: 1px solid slategray;
    border-radius: 3px;
    padding: 5px;
    z-index: 1;
    !*left: 10px;*!
    top: 100%;
    right: 30px;
    max-width: calc(100% - 60px);
    min-width: 80px;
    text-wrap: normal;
}
.errorsInGrid {
    background: rgb(255,202,192);
    margin-top: -2px;
    padding: 0px 5px 0px 5px;
    width: calc(100% - 10px);
    max-width: unset;
    white-space: normal;
    text-align: left;
    line-height: 22px;
    z-index: 1000;
}*/
.animationRight {
    animation: slide-right 0.3s backwards ease-in-out;
}
.animationLeft {
    animation: slide-left 0.3s backwards ease-in-out;
}
.animationDown {
    animation: slide-down 0.3s backwards ease-in-out;
}
.animationGrowDown {
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center
}
.animationShrinkUp {
    animation: shrinkUp 250ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes slide-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-left {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-down {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
        opacity: 0;
    }
    /*
    80% {
        transform: scaleY(1.05);
    }
    */
    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}
@keyframes shrinkUp {
    0% {
        transform: scaleY(1);
        opacity: 1;
    }
    /*
    20% {
        transform: scaleY(1.05);
        opacity: 1;
    }
    */
    100% {
        transform: scaleY(0);
        opacity: 0;
    }
}

label.inlineLabel {
    position: relative;
    left: 0;
    display: inline-block;
    width: 65%;
    margin:   0;
    padding:  0px 0px 0px 0px;
}
.inlineLabelFlex {
    width:    65%;
    margin:   0;
    padding:  2px 0px 0px 0px;
}
.resetWidthToNone {
    width: unset !important;
}

/*
label.inlineLabelTime {
    display: inline-block;
    width: 65%;
    margin:   0;
    padding:  0px 0px 0px 5px;
}
*/

/*
.inlineLine {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0px;
    margin-bottom: 10px;
}
*/
.inlineLineFlex {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;

    margin-top: 0px;
    margin-bottom: 10px;
}

.inlineField {
    display: inline-block;
    width: 35%;
    margin-top: 0;
    text-align: right;
    padding-right: 5px;
}
.inlineFieldContainer {
    display: inline-block;
    width: 35%;
    margin-top: 0;
}
.fieldTime {
    display: inline-block;
    width: 100%;
    margin-top: 0;
    padding-right: 5px;
    text-align: right;
}
/*
.inlineFieldTime {
    display: inline-block;
    width: 35%;
    margin-top: 0;
    text-align: right;
    padding-right: 5px;
}
*/

input.inlineField,
select.inlineField
{
    display: inline-block;
    width: 35%;
    padding-right: 5px;
}

.valueText {
    display: block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 0px;
}
.valueTextSmall {
    font-size: var(--xpool-label-font-size);
    line-height: var(--xpool-label-height);
    height: var(--xpool-label-height);
    font-weight: var(--xpool-label-font-weight);
    color: var(--xpool-label-text-color);
    white-space: pre-line;
}
.valueTextRightAligned {
    display: block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 5px 0 0px;
    text-align: right;
}
.centeredText {
    align-content: center;
    text-align: center;
}

.valueTextDark {
    display: block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-black-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    white-space: pre-line;
}


.inlineValueNumber {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    text-align: right;
    width: 35%;
}
.inlineValueTime {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    /*color: #313131;*/
    margin: 0 0 0 0;
    padding: 0 5px 0 5px;
    text-align: right;
    width: 35%;
}

.inlineValueTextDark {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-black-text-color);
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    width: 35%;
}

.inlineValueText {
    display: inline-block;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    min-height: var(--xpool-line-height);
    color: var(--xpool-body-text-color);
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    width: 35%;
}
.inlineValueTextRight {
    padding: 0 5px 0 5px;
    text-align:right;
    white-space: nowrap;
}
.inlineValueTextRightInner {
    float: right;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    min-height: var(--xpool-line-height);
    margin: 0 0 0 0;
}

.inputNumber {
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
}
.inputTableCell {
    /*padding-right: 5px;*/
    font-size: var(--xpool-table-font-size);
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: whitesmoke;
    border-radius: 0;
    padding-left: 0px;
}
.inputTime {
    text-align: right;
    padding-right: 5px;
    padding-left: 0px;
}


input,
select,
textarea {
    background-color: var(--xpool-form-field-background-color);

    /*
    border-color:  var(--xpool-form-field-border-color);
    border-width:  var(--xpool-form-field-border-width);
    */
    border:  var(--xpool-form-field-border);
    border-radius: var(--xpool-form-field-border-radius);

    height: var(--xpool-line-height);
    line-height: var(--xpool-line-height);
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    margin: 0 0 0 0;
    padding: 0 0 0 5px;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    resize: vertical;
    color: var(--xpool-form-input-text-color);
    /*color: #262626;*/
}

textarea {
    height: 30px;
}

label {
    /*display: block;*/
    font-size: var(--xpool-label-font-size);
    line-height: var(--xpool-label-height);
    height: var(--xpool-label-height);
    font-weight: var(--xpool-label-font-weight);
    color: var(--xpool-label-text-color);
    margin: 5px 0px 0px 0px;
}

label.inlineValueLabel {
    display: inline-block;
    margin:  10px 0px 0px 0px;
    padding:  0px 0px 0px 5px;
}
label.buttonIconLabel {
    display: inline-block;
    margin:  0;
    padding: 0 0 0 5px;
}
label.buttonIconLabel:hover {
    cursor: pointer;
}

.labelRadiosDark {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-label-font-weight);
    line-height: var(--xpool-line-height);
    margin: 0 16px 0 0;
}

.labelRadios {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-field-value-font-weight);
    line-height: var(--xpool-line-height);
    margin: 2px 16px 0 0;
}

.labelCheckboxDark {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-label-font-weight);
    margin: 5px 0.5rem 0 0;
}

.labelCheckbox {
    display: inline-block;
    font-size: var(--xpool-label-font-size);
    font-weight: var(--xpool-label-font-weight);
    margin: 0px 8px 0 0;
}

input[type="select"] {
    margin: 5px 0px 5px 0px;
}
input[type="radio"] {
    /*appearance: radio;*/
    vertical-align: text-top;
    height: 13px;
    width: auto;
}
input[type="radio"],
input[type="checkbox"] {
    border: none;
    display: inline-block;
    font-weight: var(--xpool-standard-font-weight);
}

input[type="checkbox"] {
    /*appearance: checkbox;*/
    margin: 1px 6px 0px 0px;
    height: 16px;
    width: auto;
}
.field > input[type="checkbox"] {
    vertical-align: middle;
}



textarea:disabled,
input[type="radio"]:disabled,
input[type="checkbox"]:disabled,
input:disabled,
select:disabled {
    background-color: var(--xpool-form-disabled-background-color);
    border: none;
    font-weight: var(--xpool-field-value-font-weight);
    color: var(--xpool-body-text-color);
}

/*textarea:focus,
input[type="radio"]:focus,
input[type="text"]:focus,
input[type="checkbox"]:focus,
input:focus,
select:focus {
    !*border-width: 2px;*!
    outline: none;
    !*box-shadow:none;*!
    box-shadow: var(--xpool-focus-box-shadow);
}*/

textarea:required,
input:required,
select:required {
    outline: none;
    box-shadow:none;
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container {
    width: 100%;
}
ul.react-datepicker__time-list {
    padding: 0px 5px 0px 5px !important;
}

button.xPoolButton {
    position: relative;
    -webkit-appearance: none;
            appearance: none;
    padding: 0.05rem 1rem;
    text-transform: none;
    font-size: var(--xpool-button-font-size);
    font-weight: var(--xpool-button-font-weight);
    color: var(--xpool-button-text-color);
    height: var(--xpool-button-height);

    border-width: 1px;
    border-style: solid;
    border-color: var(--xpool-primary-color-mid);
    border-radius: var(--xpool-button-border-radius);

    margin: 7px 7px 0 0;
    background-color: var(--xpool-button-background-color);
    transition: background 0.3s, border 0.3s, color 0.3s;
}

button.xPoolButton:focus {
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    box-shadow: var(--xpool-focus-box-shadow);
    outline: none;
}
button.xPoolButton:hover {
    background-color: var(--xpool-button-hover-background-color);
    color: var(--xpool-button-hover-text-color);
}
button.xPoolButton:active {
    background-color: var(--xpool-button-active-background-color);
}
button.xPoolButton:disabled {
    background-color: var(--xpool-disabled-color);
    border-color: var(--xpool-disabled-color);
}

/*
button.xPoolButton {
    position: relative;
    appearance: none;
    padding: 0.05rem 1rem;
    text-transform: var(--xpool-button-text-transform);
    font-size: var(--xpool-button-font-size);
    font-weight: var(--xpool-button-font-weight);
    color: var(--xpool-button-text-color);
    height: var(--xpool-button-height);
    border: var(--xpool-button-border);
    border-radius: var(--xpool-button-border-radius);
    margin: 7px 7px 0 0;
    background-color: var(--xpool-button-background-color);
    transition: background 0.3s, border 0.3s, color 0.3s;
}

button.xPoolButton:focus {
    !*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*!
    box-shadow: var(--xpool-focus-box-shadow);
    outline: none;
}
button.xPoolButton:hover {
    background-color: var(--xpool-button-hover-background-color);
    border: var(--xpool-button-hover-border);
    color: var(--xpool-button-hover-text-color);
    font-weight: var(--xpool-button-hover-font-weight);
}
button.xPoolButton:active {
    background-color: var(--xpool-button-active-background-color);
    color: var(--xpool-button-active-text-color);
    border: var(--xpool-button-active-border);
    font-weight: var(--xpool-button-hover-font-weight);
}
button.xPoolButton:disabled {
    background-color: var(--xpool-button-disabled-background-color);
    border: var(--xpool-button-disabled-border);
    color: var(--xpool-button-disabled-text-color);
    font-weight: var(--xpool-standard-font-weight);
}
*/

button.xPoolButtonFloatRight {
    float: right;
    margin: 7px 0px 0 7px;
}
button.xPoolButtonSmallPure {
    height: 24px;
    margin: 0;
    padding: 2px 5px 2px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
}
button.xPoolButtonInTable {
    height: 24px;
    margin: 3px 0px 3px 7px;
    padding: 2px 5px 2px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
}
button.xPoolButtonAgGrid {
    height: 22px;
    margin: 3px 0px 3px 7px;
    padding: 2px 5px 4px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
    line-height: 15px;
}
.waitingButtonSpinner {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 180%;
}

button.floatRight {
    float: right;
    margin-right: 0px;
    margin-left:  7px;
}


div.formItemPicker{
    display: grid;
    grid-template-areas:    "itemPickerLabel"
                            "available";
    grid-template-columns:  1fr;
    grid-column-gap: 5px;
    align-items: start;
    justify-items: start;
    height: 100%;
    width: 100%;
}
.itemPickerLabel{
    grid-area: itemPickerLabel;
    height: 20px;
    width: 100%;
}
.available{
    grid-area: available;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
}
/*
.pickItem{
    flex: 1 0 15%;
    border: solid thin black;
    text-align: center;
    font-size: small;
    width: 25px;
    max-width: 25px;
    margin: 5px;
}
.toPick{
    background-color: var(--xpool-button-disabled-background-color);
    color: #8C8C8C;
}
.picked{
    background-color: var(--xpool-button-active-background-color);
    color: white;
}
*/

.xpFormMultipleFieldsLine {
    display: flex;
    /*margin-top: 10px;*/
}


table {
    border: none;
    margin-right: 10px;
    margin-bottom: 20px;
    border-collapse: collapse;
    border-radius: 5px;
}

table.balanceList {
    width: 100%;
}


th {
    /*width: 150px;*/
    font-weight: var(--xpool-grid-header-font-weight);
    /*background: rgba(255,255,255,0.1);7EAFCB*/
    background-color: var(--xpool-grid-header-background-color);
    /*background: none;*/
    /*padding-left: 5px;*/
    color: var(--xpool-grid-header-text-color);
}

th.dateColumn {
}

th.amountColumn {
    text-align: right;
    padding-right: 5px;
}
td.blankDetail {
    border: none;
}
th.blankHeader {
    background-color: unset;
}
th.checkboxColumn {
    text-align: center;
    padding: 0px;
}
.checkboxTdColumn {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    align-content: center;
    margin-top: -2px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: bottom;
    padding-bottom: 0px;
}
td.disabledCell {
    background-color: lightgrey;
}
.changedCell {
    background-color: orange;
}
th.timeColumn {
    text-align: right;
    padding-right: 5px;
}
td.timeColumn {
    text-align: right;
    /*padding-right: 5px;*/
}

td {
    /*
    padding: 0px;
    padding-left: 5px;
    */
    border-bottom: var(--xpool-grid-row-border);
    vertical-align: bottom;
}
.textOverflow {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/*
tr.clickableRow:nth-child(even) {
    background: #e1e1e1;
}
tr.clickableRow:nth-child(odd) {
    background: #f1f1f1;
}
tr.nonClickableRow:nth-child(even)     {background: #e1e1e1;}
tr.nonClickableRow:nth-child(odd)      {background: #f1f1f1;}
*/


tr {
    background-color: none;
    margin: 0;
    transition: background-color 0.3s, color 0.3s;
}

tr.clickableRow:hover {
    background-color: var(--xpool-grid-hover-background-color);
    cursor: pointer;
}
tr.activeClickableRow {
    background-color: var(--xpool-grid-selected-background-color);
}
tr.activeClickableRow:hover {
    background-color: var(--xpool-grid-selected-background-color);
    cursor: pointer;
}

.HeaderRow {
    border-bottom: var(--xpool-grid-header-border);
    vertical-align: bottom;
    /*height: 50px;*/
    margin: 0;
}

.HeaderRowTop {
    margin: 0;
    text-align: center;
}

/*
.RemoveButton {
    margin: 0.3rem;
    font-size: 12px;
    text-align: center;
    font-style: oblique;
    font-weight: var(--xpool-bold-font-weight);
    background-color: #1695A3;
    border-radius: 5px;
}
*/

.filterFormContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: var(--xpool-grid-row-border);
    vertical-align: middle;
    /*margin-top: -10px;*/
    margin-top: 5px;
    padding-bottom: 3px;
    /*width: calc(100% + 30px);*/
}
.filterFormField {
    margin-right : 10px;
    vertical-align: middle;
}
.filterFormButton {
    vertical-align : middle;
    padding-top    : 0;
    display        : inline-block;
    float          : right;
    margin-left    : 10px;
    margin-right   : -7px;
    margin-top     : 6px;
}
.filterFormRightButtonsGroup {
    /*padding-right: 30px;*/
    vertical-align: middle;
    margin-left  : auto;
}

.xpMuiRightAlignInput {
    text-align: right;
}

.editableTextField {
    border-radius: 7px;
    background-color: rgba(255,140,255,0.6);
    pointer-events: auto;
}
.editableTextFieldWithGenerations{
    border-style: solid;
    border-width: 2px;
    border-color: dodgerblue;
    /*background-color: rgba(30,255,255,0.6);*/
}
.languageLinePanel{
    margin-bottom: 20px;
}
.inlineLangCurrent {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 5px);
    margin:   0;
    text-align: left;
}
.inlineLangNew {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 5px);
    margin-left:   10px;
    text-align: left;
}
.newTextLabel {
    margin:   21px 0 0 0 ;
    padding:  0px 0px 0px 5px;
}
.textGenerationsHeader {
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-standard-font-weight);
}
.textGenerationsPanel {
    padding: 0 !important;
}
.generationsTableBody {
    overflow: auto;
    width: 100%;
    height: 100%;
    font-size: var(--xpool-table-font-size);
}
.generationsTableCell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
    font-size: var(--xpool-table-font-size);
    padding-left: 5px;
}
button.xPoolButtonInTextGenerationTable {
    height: 20px;
    margin: 2px 0px 2px 7px;
    padding: 1px 5px 1px 5px;
    font-size: var(--xpool-table-font-size);
    font-weight: 600;
}




/***********************************/
/*      Field line styling         */
/***********************************/
.xpFormFieldLine {
    position: relative;
    display: block;
    margin-bottom: 5px;
}
.xpFormFieldLine:last-child {
    /*margin-bottom: 0;*/
}
.xpFormFieldInline {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 10px;
}
.xpFormFieldInline:last-child {
    /*margin-bottom: 0;*/
}
.xpFormFieldLineNoBottomMargin {
    position: relative;
    display: block;
    margin-bottom: 0px;
}


/***********************************/
/*      Input element styling      */
/***********************************/
.xpFormInputAlignRight {
    padding: 0 5px 0 5px;
    text-align:right;
    white-space: nowrap;
}
.xpFormInputNoLabel {
    padding-top: 7px;
    font-size: var(--xpool-table-font-size);
    height: calc(var(--xpool-line-height) + 4px);
    line-height: calc(var(--xpool-line-height) - 4px);
}
.xpFormInputTableCell {
    border-width: 0 1px 0 1px !important;
    border-style: solid !important;
    border-color: whitesmoke !important;
    border-radius: 0 !important;

    padding-top: 10px !important;
    font-size: var(--xpool-table-font-size) !important;
    /*height: calc(var(--xpool-line-height) + 3px) !important;*/
    /*line-height: calc(var(--xpool-line-height) - 3px) !important;*/
}
.xpFormCheckboxInTable {
    height: calc(var(--xpool-line-height) + 3px);
    text-align: center;
    padding-top: 0px;
    padding-left: 12px;
    margin-right: -10px;
    margin-top: -3px;
}

/***********************************/
/*  Current value element styling  */
/***********************************/
.xpFormValueTextAlignRightInner {
    float: right;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    min-height: var(--xpool-line-height);
    margin: 0 0 0 0;
}
.xpFormValueTextInner {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    min-height: var(--xpool-line-height);
    margin: 0 0 0 0;
}
.disabledFieldAlignRight {
    padding-right: 5px;
    text-align: right;
}

/***********************************/
/*      Error element styling      */
/***********************************/
.xpFormError {
    position: absolute;
    font-size: var(--xpool-label-font-size);
    color: var(--xpool-form-error-text-color);
    animation: slide-down 0.3s backwards ease-in-out;
    font-weight: var(--xpool-standard-font-weight);
    /*background: var(--xpool-form-error-background-color);*/
    background: rgba(255,150,150,0.8);
    /*border: 1px solid slategray;*/
    /*border-radius: 3px;*/
    padding: 2px 5px 2px 5px;
    z-index: 1;
    text-wrap: normal;
    white-space: normal !important;
}
.xpFormErrorBelow {
    margin-top: -2px;
    top: 100%;
    right: 20px;
    max-width: calc(100% - 60px);
    min-width: 80px;
}

/***********************************/
/*  Before change element styling  */
/***********************************/
.xpFormBeforeChangeInInputCell {
    position: absolute;
    top: -4px;
    z-index: 1;
}
.xpFormBeforeChangeInInputCellAlignRight {
    position: absolute;
    top: -4px;
    right: 5px;
    z-index: 1;
}
.xpFormBeforeChangeInCheckboxInputCell {
    position: absolute;
    top: -4px;
    left: -2px;
    z-index: 1;
}
.xpFormBeforeChangeUnderInlineLabel {
    position: absolute;
    top: 18px;
    left: 0px;
}
.xpFormToggleFieldBeforeChange {
    text-align: center;
    width: 100%;
    height: 11px;
    margin-top: -10px;
    margin-left: -1px;
}

/***********************************/
/*     Label element styling       */
/***********************************/
.xpFormLabel {
    margin: 0 10px 0 0;
}

/***********************************/
/*     Radio element styling       */
/***********************************/
.xpFormRadioButtonWrapper {
    display: inline-block;
    vertical-align: baseline;
    height: 13px;
    width: 13px;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 3px -1px 0;
}
.xpFormRadioButtonWrapper:focus {
    box-shadow: var(--xpool-focus-box-shadow);
}
.xpFormRadioLabel {
    display: inline-block;
    margin: 0px 14px 0 0;
}


.xpChartReferenceLine {
    border: none;
    height: 12px;
    line-height: 12px;
    text-align: center;
    padding: 0;
    /*position: absolute;*/
    font-size: 12px;
    background-color: white;
    opacity: 1;
}

.xpChartYLineLabel {
    border: none;
    height: 12px;
    line-height: 12px;
    text-align: center;
    padding: 0;
    /*position: absolute;*/
    font-size: 12px;
    background-color: white;
    opacity: 1;
}

.userMessagesContainer {
    width: 100%;
    margin-top: 47px;
    padding: 2px;
    position: sticky;
    top: 50px;
    z-index: 500;
}
.userMessagesContainerNoMenu {
    margin-top: -3px;
    top: 0;
}
.confirmMessageButton {
    float: right;
    /*display: inline-block;*/
    text-align: right;
    width: 20px;
    vertical-align: middle;
    border-radius: 10px;
    margin: 2px 0px 2px 0px;
}

.confirmMessageButton:hover {
    background: rgba(0,0,0,0.1);
    border-style: solid;
    border-color: darkslategray;
    cursor: pointer;
}
.confirmMessageButton:active {
    background: rgba(0,0,0,0.4);
    border-style: solid;
    border-color: darkslategray;
    cursor: pointer;
}
.userMessageTopicDisplay {
    display: inline-block;
    width: 95px;
    /*border: 1px solid darkgray;*/
    /*background: lightgray;*/
    border-radius: 3px;
    font-size: 14px;
    padding: 2px 2px 2px 5px;
    margin: 0px 5px 0px 0px;
    text-align: left;
    color: darkgray;
}

.userMessage {
    display: inline-block;
    width: 100%;
    border: 1px solid slategray;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 2px;
    text-align: left;
    color: var(--xpool-user-message-text-color);
}

button.xPoolButtonInUserMessage {
    height: 24px;
    margin: 0px 7px 0px 0px;
    padding: 2px 5px 2px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
}

.userMessageEnter {
    animation: slide-down-fade-id 0.3s backwards ease-in-out;}
.userMessageExit {
    animation: slide-up-fade-out 0.3s backwards ease-in-out;}
.userMessageSlideUp {
    animation: slide-up 0.3s backwards ease-in-out;}
.userMessageSlideDown {
    animation: slide-dwn 0.3s backwards ease-in-out;}

@keyframes slide-down-fade-id {
    from {
        transform: translateY(-100%);
        opacity: 0;}
    to {
        transform: translateY(0%);
        opacity: 1;}
}
@keyframes slide-up-fade-out {
    from {
        transform: translateY(0%);
        opacity: 1;}
    to {
        transform: translateY(-100%);
        opacity: 0;}
}
@keyframes slide-dwn {
    from { transform: translateY(var(--userMessageSlideHeight)); }
    to   { transform: translateY(0px); }
}
@keyframes slide-up {
    from { transform: translateY(0px); }
    to {   transform: translateY(var(--userMessageSlideHeight)); }
}



.appContainerSlideUp {
    animation: slide-up-var 0.3s backwards ease-in-out;
}
@keyframes slide-up-var {
    from { transform: translateY(0px); }
    to {   transform: translateY(var(--userMessageSlideHeight)); }
}
.appContainerSlideDown {
    animation: slide-dwn-var 0.3s backwards ease-in-out;
}
@keyframes slide-dwn-var {
    from { transform: translateY(var(--userMessageSlideHeight)); }
    to   { transform: translateY(0px); }
}


.userInfoMessage {
    background: var(--xpool-user-message-info-background-color);}
.userWarningMessage {
    background: var(--xpool-user-message-warning-background-color);}
.userErrorMessage {
    background: var(--xpool-user-message-error-background-color);}

