
table {
    border: none;
    margin-right: 10px;
    margin-bottom: 20px;
    border-collapse: collapse;
    border-radius: 5px;
}

table.balanceList {
    width: 100%;
}


th {
    /*width: 150px;*/
    font-weight: var(--xpool-grid-header-font-weight);
    /*background: rgba(255,255,255,0.1);7EAFCB*/
    background-color: var(--xpool-grid-header-background-color);
    /*background: none;*/
    /*padding-left: 5px;*/
    color: var(--xpool-grid-header-text-color);
}

th.dateColumn {
}

th.amountColumn {
    text-align: right;
    padding-right: 5px;
}
td.blankDetail {
    border: none;
}
th.blankHeader {
    background-color: unset;
}
th.checkboxColumn {
    text-align: center;
    padding: 0px;
}
.checkboxTdColumn {
    display: block;
    width: fit-content;
    align-content: center;
    margin-top: -2px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: bottom;
    padding-bottom: 0px;
}
td.disabledCell {
    background-color: lightgrey;
}
.changedCell {
    background-color: orange;
}
th.timeColumn {
    text-align: right;
    padding-right: 5px;
}
td.timeColumn {
    text-align: right;
    /*padding-right: 5px;*/
}

td {
    /*
    padding: 0px;
    padding-left: 5px;
    */
    border-bottom: var(--xpool-grid-row-border);
    vertical-align: bottom;
}
.textOverflow {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/*
tr.clickableRow:nth-child(even) {
    background: #e1e1e1;
}
tr.clickableRow:nth-child(odd) {
    background: #f1f1f1;
}
tr.nonClickableRow:nth-child(even)     {background: #e1e1e1;}
tr.nonClickableRow:nth-child(odd)      {background: #f1f1f1;}
*/


tr {
    background-color: none;
    margin: 0;
    transition: background-color 0.3s, color 0.3s;
}

tr.clickableRow:hover {
    background-color: var(--xpool-grid-hover-background-color);
    cursor: pointer;
}
tr.activeClickableRow {
    background-color: var(--xpool-grid-selected-background-color);
}
tr.activeClickableRow:hover {
    background-color: var(--xpool-grid-selected-background-color);
    cursor: pointer;
}

.HeaderRow {
    border-bottom: var(--xpool-grid-header-border);
    vertical-align: bottom;
    /*height: 50px;*/
    margin: 0;
}

.HeaderRowTop {
    margin: 0;
    text-align: center;
}

/*
.RemoveButton {
    margin: 0.3rem;
    font-size: 12px;
    text-align: center;
    font-style: oblique;
    font-weight: var(--xpool-bold-font-weight);
    background-color: #1695A3;
    border-radius: 5px;
}
*/
