.editableTextField {
    border-radius: 7px;
    background-color: rgba(255,140,255,0.6);
    pointer-events: auto;
}
.editableTextFieldWithGenerations{
    border-style: solid;
    border-width: 2px;
    border-color: dodgerblue;
    /*background-color: rgba(30,255,255,0.6);*/
}
.languageLinePanel{
    margin-bottom: 20px;
}
.inlineLangCurrent {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 5px);
    margin:   0;
    text-align: left;
}
.inlineLangNew {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 5px);
    margin-left:   10px;
    text-align: left;
}
.newTextLabel {
    margin:   21px 0 0 0 ;
    padding:  0px 0px 0px 5px;
}
.textGenerationsHeader {
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-standard-font-weight);
}
.textGenerationsPanel {
    padding: 0 !important;
}
.generationsTableBody {
    overflow: auto;
    width: 100%;
    height: 100%;
    font-size: var(--xpool-table-font-size);
}
.generationsTableCell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
    font-size: var(--xpool-table-font-size);
    padding-left: 5px;
}
button.xPoolButtonInTextGenerationTable {
    height: 20px;
    margin: 2px 0px 2px 7px;
    padding: 1px 5px 1px 5px;
    font-size: var(--xpool-table-font-size);
    font-weight: 600;
}

