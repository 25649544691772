
.integrationStatusContainer {
    width: 100%;
    /*padding: 2px;*/
}

.itemText {
    font-size: 3.5vw;
    font-weight: bold;
    width: 52%;
    vertical-align: top;
    /*height: 16%;*/
    /*margin: 8% 0;*/
    /*line-height: 0;*/
}

.headerText {
    padding: 5px;
    font-weight: var(--xpool-header-font-weight);
    /*font-size: 5vw;*/
    font-size: 1%;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
    color: var(--xpool-header-text-color);
}

.showEventButton {
    position: absolute;
    /*height: 30px !important;*/
    bottom: 0;
    left: 0;
}

.integrationStatusItem {
    display: inline-block;
    width: 100%;
    border: 1px solid slategray;
    border-radius: 5px;
    padding: 1%;
    margin-bottom: 1%;
    text-align: left;
    color: var(--xpool-body-text-color);
    background: var(--xpool-wizard-status-bar-background-color)
}
.menuStatusLight {
    display: inline-block;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    border-radius: 50%;
    /*border: 10px inset rgba(51,153,0,0.65);*/
    background: darkgray;
}
.menuStatusLightShortName {
    color: var(--xpool-body-text-color);
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: 4px;
    width: 21px;
    text-align: center;
}
.statusLight {
    display: inline-block;
    float: right;
    width: 16%;
    padding-top: 16%; /* 1:1 Aspect Ratio */
    border-radius: 50%;
    box-shadow:0px 0px 0px 2px var(--xpool-wizard-status-bar-background-color) inset;
    /*border: 10px inset rgba(51,153,0,0.65);*/
    vertical-align: middle;
    background: darkgray;
}
.statusLightOk {
    background: radial-gradient(white -80%, #2dc937);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightStarting {
    background: radial-gradient(white -80%, #9BEFA1);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightWarning {
    background: radial-gradient(white -80%, #E7BC16);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightError {
    background: radial-gradient(white -80%, #E77C16);
    box-shadow:0px 0px 0px 2px dimgray inset;
}
.statusLightDown {
    background: radial-gradient(white -80%, #fe5755);
    box-shadow:0px 0px 0px 2px dimgray inset;
}

.tooltip-inner {
    white-space: pre-wrap;
}
