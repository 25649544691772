.xpGridDetailContainer {
    padding: 10px 0px 20px 5px;
    background-color: white;
    border-bottom: var(--xpool-grid-row-border);
    cursor: default !important;
}
.xpGridDetailSelector {
    padding-top: 2px;
    vertical-align: top;
    display: inline-block;
    width: 30px;
    height: 100%;
}
.xpGridDetailLine {
    width: 22px;
    padding: 3px 10px 12px 10px;
}
.xpGridDetailLineFill {
    width: 100%;
    height: 100%;
    background-color: #E1E1E1;
    /*background-color: lightgray;*/
    border-radius: 2px;
}

.xpGridDetailIconSelected {
    overflow: hidden;
    padding: 3px 2px 2px 2px;
    margin-bottom: 5px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: var(--xpool-primary-color-strong);
    text-align: center;
    vertical-align: middle;
    z-index: 10;
}

.xpGridDetailIconSelected:hover {
}
.xpGridDetailIconNotSelected {
    /*background: var(--xpool-secondary-color-mid);*/
    background: var(--xpool-disabled-color);
}
.xpGridDetailIconNotSelected:hover {
    background: var(--xpool-primary-color-mid);
    cursor: pointer;
}

.xpGridDetailComponent {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 30px);
}
.xpGridMenuButton {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
}
.xpGridMenuButton:hover {
    opacity: 1;
}

.inputNumberInGrid {
    text-align: right;
    margin: 0 -5px 0 -5px;
    padding: 0 5px 0 5px;
}
.xpGridFormField {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin: 0 -5px 0 -5px;
    padding: 0 5px 0 5px;
}
.xpGridFormBeforeChangeField {
    margin-right: 10px;
}
.xpGridFormFieldValue {
}
.xpGridEnforceEditBackground {
    background-color: var(--xpool-form-field-background-color);
    border:  var(--xpool-form-field-border);
    border-radius: var(--xpool-form-field-border-radius);

}

.xpHeaderAlignMiddle {
    /*text-align: right;*/
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: nowrap;
}
.xpHeaderAlignRight {
    /*text-align: right;*/
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
}
.xpGroupHeaderAlignRight {
    /*text-align: right;*/
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding-right: 16px;
}
.highlightChangedFormFieldInGrid {
    background-color: rgba(250,255,152,0.5) !important;
}
.highlightErrorInFormFieldInGrid {
    background-color: rgba(255,190,170,0.5) !important;
    height: var(--xpool-line-height);
}

/*
************************************
Row class rule classes. All with ag-theme-balham in selector for precedence
*************************************
*/
.ag-theme-balham .xp-grid-disabled-row {
    background: lightgrey !important;
}
.ag-theme-balham .xp-grid-selected-order-row {
    background: var(--xpool-grid-selected-order-background-color) !important;
}
.ag-theme-balham .xp-grid-inBalance-order-row {
    background: var(--xpool-grid-inBalance-order-background-color);
    font-style: italic;
    color: #828282;
    /*color: black;*/
    /*font-weight: 200;*/
}
.ag-theme-balham .xp-grid-removed-row {
    background: var(--xpool-form-card-disabled-background-color);
    font-style: italic;
    color: #828282;
}
.ag-theme-balham .xp-grid-removed-row div {
    color       : inherit !important;
    font-style  : inherit !important;
    font-weight : inherit !important;
}

.ag-theme-balham .xp-grid-added-row {
    background: var(--xpool-form-card-new-background-color);
}
/*background: var(--xpool-grid-new-line-background-color);*/
/*
.ag-theme-balham .xp-grid-added-row div {
    background: var(--xpool-form-card-new-background-color);
}
*/
/*background: var(--xpool-grid-new-line-background-color);*/

.ag-theme-balham .xp-grid-neutral-row {
    background: none !important;
    font-style: unset !important;
    color: #8C8C8C;
    font-weight: 200;
}

.ag-theme-balham .xp-grid-executed-order-row {
    background: var(--xpool-grid-executed-order-background-color);
    font-style: italic;
    color: #8C8C8C;
    /*font-weight: 200;*/
}
.ag-theme-balham .xp-grid-executed-order-row div {
    color       : inherit !important;
    font-style  : inherit !important;
    font-weight : inherit !important;
}
.ag-theme-balham .xp-grid-rejected-order-row {
    background: var(--xpool-grid-rejected-order-background-color);
}
.ag-theme-balham .xp-grid-inactive-row {
    background: var(--xpool-grid-inactive-rowbackground-color);
}
.ag-theme-balham .xp-grid-onHold-order-row {
    background: var(--xpool-grid-onHold-order-background-color);
}
.ag-theme-balham .xp-grid-default-order-row {
    background: none !important;
}
.ag-theme-balham .xp-grid-no-background {
    background: none;
}

/*
************************************
Styling for cash pool accounting grid
*************************************
*/
.xp-grid-ingoing-outgoing-balance-row {
    border-bottom-style: solid !important;
    border-bottom-color: gray !important;
    border-bottom-width: 1px !important;
    font-weight: 600 !important;
}

/*
************************************
Styling for view dialog in dev mode for execution rights
*************************************
*/
.xpoolModalViewExecutionRights {
    width: 90% !important;
}


.changedRendererCell {
    background: var(--xpool-grid-flash-background-color);
}

.flashRendererCell {
    background: rgba(0,0,0,0);

    transition-duration: 1000ms;
    transition-property: background;
    transition-timing-function: ease-in-out;
}
