.reportFilterSelect {
    min-width    : 150px;
    max-width    : 250px;
    width        : calc(20% - 20px);
}
.reportFilterDate {
    min-width    : 100px;
    max-width    : 120px;
    width        : calc(10% - 20px);
}
.reportFilterCurrency {
    min-width    : 50px;
    max-width    : 150px;
    width        : calc(20% - 20px);
}
.reportFilterViewReportGroup {
    min-width: 300px;
    max-width: 500px;
    width: 30%;
}
.reportFilterReportType {
    float        : right;
    min-width    : 150px;
    max-width    : 250px;
    width        : calc(100% - 150px);
}


.reportModalLargeHeader {
    font-size: 40px;
}

.reportModalHeader {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 15px;
    color: var(--xpool-header-text-color);
    text-align: center;
}

.reportModalHead {
    width: 100%;
    height: 25px;
    vertical-align: bottom;
    padding-bottom: 0px;
    padding-right: 20px;
    padding-left : 20px;
    margin-bottom: -5px;
    /*width: calc(100% + 30px);*/
}
.reportModalConfig {
    display      : inline-block;
    margin-right : 10px;
}
.reportModalCloseButton {
    display      : inline-block;
    float        : right;
    margin-top   : -18px;
    margin-left  : 3px;
}

