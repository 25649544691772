@import url(https://fonts.googleapis.com/css?family=Lato:400,300);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:200,400,600);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:200,400,600);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:200,400,600);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Serif:200,400,600);
@import url(https://fonts.googleapis.com/css?family=Yantramanav:200,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:200,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Yantramanav:200,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Tinos:ital@1&display=swap);

/*$color-headers: #434343;*/

/* Needed for not getting an ugly blue ring around tooltips in graphs */
:focus-visible {
    outline: 0 !important;
}

.xpWarningBanner {
    display: block;
    width: 100%;
    border: 1px solid slategray;
    border-radius: 0px;
    padding: 5px;
    margin-bottom: 0px;
    text-align: center;
    color: var(--xpool-user-message-text-color);
    background: var(--xpool-user-message-warning-background-color);
}

.xpoolToolTip {
    white-space: pre-wrap;
    z-index: 2000 !important;
    background: lightgray;
    opacity: 0.9 !important;
}











/*
.xpResizableContainer {
    border-bottom: 2px solid var(--xpool-primary-color-x-weak);
}
.xpResizableContainerDisabled {
    border-bottom: none;
}
.xpResizableContainerManaged {
    border-bottom: 2px solid var(--xpool-primary-color-weak);
}
*/




.xpResizableHandle {
    background: var(--xpool-primary-color-x-weak);
}
.xpResizableHandle:hover {
    background: var(--xpool-primary-color-weak);
}
.xpResizableHandle:active {
    background: var(--xpool-primary-color-mid);
}
.xpResizableHandleDisabled {
    background: var(--xpool-primary-color-x-weak);
}
.xpResizableHandleManaged {
    background: var(--xpool-primary-color-weak);
}
.xpResizableHandleManaged:hover {
    background: var(--xpool-primary-color-mid);
}
.xpResizableHandleManaged:active {
    background: var(--xpool-primary-color-mid);
}
.xpVerticalHandle {
    width: 12px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-x-weak) 5px, var(--xpool-primary-color-x-weak) 7px, rgba(0,0,0,0) 7px);
}
.xpVerticalHandle:hover {
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-weak) 5px, var(--xpool-primary-color-weak) 7px, rgba(0,0,0,0) 7px);
}
.xpVerticalHandle:active {
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-mid) 5px, var(--xpool-primary-color-mid) 7px, rgba(0,0,0,0) 7px);
}
.xpVerticalHandleDisabled {
    width: 12px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0) 5px, var(--xpool-primary-color-x-weak) 5px, var(--xpool-primary-color-x-weak) 7px, rgba(0,0,0,0) 7px);
}
/*
.xpVerticalHandle {
    border-left: 5px solid rgba(255, 255, 255, 1);
    border-right: 5px solid rgba(255, 255, 255, 1);
}
*/
.xpHorizontalHandle {
    border-top: 5px solid rgba(255, 255, 255, 1);
    border-bottom: 5px solid rgba(255, 255, 255, 1);
}


/*MT20180322: Added this to override user agent stylesheet*/
ul, menu, dir {
    -webkit-padding-start: 0px;
}


/* Override for bootstrap menu component*/
.navbar {
    border-radius: 0px;
    border: none;
    background-color: rgb(34,34,34) !important;
    padding: 0px 0 0px 0;
}
.nav-link {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}
.navbar-brand {
    padding-top: 7px !important;
    padding-right: 0px !important;
}
.dropdown-item {
    font-weight: var(--xpool-standard-font-weight)  !important;
    color: var(--xpool-body-text-color);
    font-size: var(--xpool-label-font-size)  !important;
    padding-left:  20px !important;
    padding-right: 20px !important;
}
.dropdown-item.disabled {
    color: #adb5bd !important;
}

/*.navbar .nav li.dropdown > .dropdown-toggle .caret {*/
/*.dropdown-menu .dropdown-toggle .caret {*/
.dropdown-menu .dropdown-toggle::after {
    border-bottom-color: #adb5bd !important;
    border-top-color: #adb5bd !important;
}

.subMenuDropDown {
    color: var(--xpool-body-text-color) !important;
    font-size: var(--xpool-label-font-size)  !important;
}
.dropdown-menu .dropdown-menu { /* Needed to get sub-menu for numbering formats to stay in right place */
    right: -50px !important;
    left: auto !important;
}

.dropdown-menu .dropdown-toggle {
    padding: 0 !important;
    /*padding: 4px 20px 4px 20px !important;*/
}

.clientSelectorMenuList {
    max-height: 500px;
    overflow: auto;
}
.clientSelectorSearchField {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-body-text-color);
    font-size: var(--xpool-label-font-size);
    padding-left: 20px;
    padding-right: 20px;
}


/*
.dropdown-toggle {
    max-width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
}
*/

.navbar-brand { /*Hight of top menu bar */
    height: 45px !important;
    padding: 10px 15px 15px 10px;
}

ul[aria-labelledby=client-nav-dropdown] { /* MT: Needed to get client-menu scrollable when many clients in system */
    max-height: 500px;
    overflow: scroll;
}

.nav > li > a {
    padding-left:  10px;
    padding-right: 5px;
}
.nav:last-child{
    padding-right: 10px;
}

.refreshIcon {
    display: inline-block !important;
    width: 22px;
    vertical-align: middle;
    padding-left: 0px !important;
    padding-bottom: 5px;
    margin-right: 5px;
}
.refreshIcon:hover {
    cursor: pointer;
}
.refreshIconNavItem {
    padding: 13px 15px 13px 5px;
}

.NFCLogo {
    display: inline-block !important;
    width: 70px;
    visibility: visible;
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        padding-left: 0;
    }
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    padding-left: 15px;
}

/*
@media (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px;
    }
    .dropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        !*margin: 7.5px -15px;*!
    margin: 7.5px 50px 7.5px -15px
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
!* since 3.1.0 *!
               .navbar-collapse.collapse.in {
                   display: block!important;
               }
    .collapsing {
        overflow: hidden!important;
    }
}
*/


/*
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}
*/







.adminMain {
    display: flex;
    flex-wrap: wrap;
    /*border: 1px solid red;*/
}
.adminSelector {
    flex: 20%;
    max-width: 20rem;
    min-width: 16rem;
    padding-right: 25px;
    /*border: 1px solid pink;*/
}
.welcomeScreen {
    width: 100%;
    max-width: 40rem;
    min-width: 10rem;
    margin: auto;
    text-align: center;
    /*border: 1px solid pink;*/
}
.adminData2col{
    display: flex;
    flex: 75%;
    flex-wrap: wrap;
    /*border: 1px solid blue;*/
}
.adminData3col{
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    /*border: 1px solid blue;*/
}
.adminData1col{
    display: flex;
    flex: 25%;
    flex-wrap: wrap;
    /*border: 1px solid blue;*/
}
.adminDataColumn{
    flex: 50%;
    max-width: 30rem;
    min-width: 16rem;
    /*border: 1px solid yellow;*/
    padding-right: 30px;
    flex-shrink: 0;
}
.adminDataColumn2{
    flex: 33%;
    max-width: 20rem;
    min-width: 16rem;
    /*border: 1px solid yellow;*/
    padding-right: 30px;
}

body {
    font-family: var(--xpool-font-family);
    background-color: white;
    color: var(--xpool-body-text-color);
    text-align: left;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-standard-font-weight);
    height: 100%;
}

.oxyGenApp {
    /*
    min-height: 100%;
    */
    height: 100%;
}

html {
    font-size: var(--xpool-standard-font-size);
    height: 100%;
    width: 100%;
    margin: 0;
}

.clickToExpand {
    font-family: var(--xpool-font-family);
    font-size: 13px;
    font-weight: 300;
    color: dimgray;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 5px;
}

.standardTextColor {
    color: var(--xpool-body-text-color);
}

a, ul, li, p {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-body-text-color);
}

.clientItem, dropdown-menu {
    font-weight: var(--xpool-standard-font-weight);
    color: var(--xpool-label-text-color);
}

/*
MT20210127: I removed this because I cannot understand what it does. I did not see any impact on the menu when I removed it.
@media (max-width: 767px)
<style>…</style>
          .navbar-nav .open .dropdown-menu > li > a {
              line-height: 20px;
          }
*/

.content {
    padding: 15px;
    margin-top: -5px;
    height: 100%;
}
.contentUserApp {
    /*margin-top: -5px;*/
    height: 100%;
}

h1,h2,h3,h4,h5 {
    font-family: var(--xpool-header-font-family);
}

h1 {
    font-weight: var(--xpool-header-font-weight);
}
h2 {
    padding-left: 5px;
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-bottom: 10px;
    color: var(--xpool-header-text-color);
    text-align: center;
}
h3 {
    padding: 5px 5px 0px 5px;
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
    color: var(--xpool-header-text-color);
    font-size: var(--xpool-modal-label-font-size);
}

h4 {
    padding: 5px;
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
    color: var(--xpool-header-text-color);
}

h5 {
    padding: 5px;
    font-size: var(--xpool-standard-font-size);
    font-weight: var(--xpool-header-font-weight);
    color: var(--xpool-header-text-color);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
}

.content li {
    margin-bottom: 10px;
}

.xpSliderContainer {
    width: 100%;
    height: 40px;
}
.xpChartSliderContainer {
    width: 100%;
    padding: 0 35px 0 70px;
    height: 20px;
}

.languageItem {
    margin-left: 15px;
}

.flagImage {
    width: 21px;
    vertical-align: middle;
    background-color: white;
}

.flagImageInMenu {
    border: solid 1px lightgray;
}

.smallFlagIcon {
    width: 45px;
    vertical-align: middle;
    /*background-color: white;*/
}

.userImage {
    width: 18px;
    margin-top: -5px;
    vertical-align: middle;
}

.spinner {
    width: 70px;
}
.textSpinner {
    width: 36px;
}
.loadingSpinner {
    width: 50px;
    margin-left: -18px;
    margin-right: -5px;
    margin-top: -2px;
}
.smallTableSpinner {
    width: 25px;
}
.smallTableSpinnerPosAbsolute {
    position: absolute;
    left: -4px;
    top:  -3px;
    width: 32px;
}

.construction {
    width: 500px;
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid #434343;
}

.floatingDiv {
    position: absolute;
    /*-webkit-transform: translate3d(0,0,0);*/
    left: 450px;
    top:  300px;
    background: white;
    padding: 0px 7px 7px 7px;
    border: 1px solid gray;
    width: 350px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    /*z-index: 10;*/
}
.draggableHandle:hover {
    cursor: pointer;
}

img.buttonIcon {
    opacity: 0.5;
    height: 18px;
    border: none;
    padding: 0px 0 2px 0;
}
img.buttonIconInline {
    display: inline-block;
}

img.buttonIcon:hover {
    /*border-color: darkslategray;*/
    cursor: pointer;
    opacity: 0.8;
}
img.buttonIcon:active {
    /*border-color: darkslategray;*/
    cursor: pointer;
    opacity: 1;
}
img.buttonIconDisabled:hover {
    opacity: 0.5;
    cursor: not-allowed;
}
img.buttonIconDisabled:active {
    opacity: 0.5;
    cursor: not-allowed;
}
.xpChartToolTipContainer {
    /*background: ghostwhite;*/
    background: rgba(248, 248, 255, 0.8);
    border: solid 1px lightgray;
    padding: 5px;
    margin: 15px;
    /*position: absolute;*/
}
.xpMiniChartToolTipContainer {
    /*background: ghostwhite;*/
    background: rgba(248, 248, 255, 0.8);
    font-size: 10px;
    /*border: solid 1px lightgray;*/
    /*padding: 5px;*/
    /*margin: 15px;*/
    /*position: absolute;*/
}
.xpChartActiveDot {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    opacity: 0.6;
    border: none;
    border-radius: 12px;
}
.xpChartActiveDotInner {
    /*margin: 3px;*/
    position: absolute;
    top: 5px;
    left: 5px;
    height: 14px;
    width: 14px;
    opacity: 1;
    background: none;
    border: 2px solid ghostwhite;
    border-radius: 7px;
}
.xpChartActiveDotArrowBase {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
}
.xpChartActiveDotArrowUp {
    top: 5px;
}
.xpChartActiveDotArrowDown {
    top: -5px;
}
.xpTabsHeader {
    padding: 0px 10px;
}
