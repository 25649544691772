.auditFilterSelect {
    min-width    : 150px;
    max-width    : 250px;
    width        : calc(20% - 20px);
}
.auditFilterDate {
    width: 120px;
}
.auditActionBaseColumn    {width: calc(33% - 18px);}
.auditActionContentColumn {width: calc(67% - 18px);}

