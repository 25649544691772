/*
Below all global css variables are declared. These will be valid in all CSS-code in the application.
The declarations are replaced in run-time when changing styling through setStyling thunk.
*/

:root {
    /*------ primary / secondary colors ---------------------------------*/
    /*-------------------------------------------------------------------*/
    /*--xpool-primary-color:           #6aa5c1;*/
    --xpool-primary-color-x-strong:  #6aa5c1;
    --xpool-primary-color-strong:    #6aa5c1;
    --xpool-primary-color-mid:       #b0cfde;
    --xpool-primary-color-weak:      #d7e7ee;
    --xpool-primary-color-x-weak:    #d7e7ee;

    --xpool-secondary-color-strong:  #6aa5c1;
    --xpool-secondary-color-mid:     rgb(106 165 193);
    --xpool-secondary-color-weak:    #6aa5c1;

    --xpool-primary-background-color:   lightgray;
    --xpool-secondary-background-color: lightgray;

    --xpool-disabled-color:          lightgray;


    /*------ buttons styling --------------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-button-height:                  25px;
    --xpool-button-border-radius:           4px;

    --xpool-button-font-weight:             300;
    --xpool-button-font-size:               12px;
    --xpool-button-text-color:              black;

    --xpool-button-background-color:          #A8D5EA;
    --xpool-button-hover-background-color:    #9ECADD;
    --xpool-button-active-background-color:   #8FB6C8;
    --xpool-button-hover-text-color:          var(--xpool-button-text-color);

    --xpool-button-in-table-font-size:      12px;


    /*------ form styling -----------------------------------------------*/
    /*-------------------------------------------------------------------*/

    /*--xpool-form-error-background-color:    rgba(255,202,192,0.7);*/
    --xpool-form-field-background-color:    rgba(255,255,255,0.8);
    --xpool-form-disabled-background-color: rgba(255,255,255,0);

    --xpool-form-card-update-background-color:   #FFFFF3;
    --xpool-form-updated-field-background-color: rgba(250,255,152,0.5);

    --xpool-form-card-new-background-color:      #E5FDDD;
    --xpool-form-card-disabled-background-color: #FFE5E6;

    --xpool-form-input-text-color:          #434343;

    --xpool-label-font-weight:              300;
    --xpool-label-font-size:                14px;
    --xpool-label-text-color:               #434343;
    --xpool-label-height:                   18px;

    --xpool-field-value-font-weight:        400;

    --xpool-form-before-change-text-color:  #DD5400;
    --xpool-old-value-font-weight:          400;
    --xpool-old-value-font-size:            12px;

    --xpool-form-error-text-color:          #434343;

    --xpool-form-field-border:              1px solid #bcbcbc;
    --xpool-form-field-border-radius:       4px;

    /*------ xp grid styling --------------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-grid-header-background-color:   #D2EEFB;

    --xpool-grid-highlight-color-base:      106,165,193;
    --xpool-grid-flash-background-color:    rgba(var(--xpool-grid-highlight-color-base),0.2);
    /*--xpool-grid-hover-background-color:    rgba(var(--xpool-secondary-color-strong), 0.08);*/
    --xpool-grid-hover-background-color:    rgba(var(--xpool-grid-highlight-color-base), 0.08);
    --xpool-grid-selected-background-color: rgba(var(--xpool-grid-highlight-color-base),0.2);
    --xpool-grid-new-line-background-color: rgba(var(--xpool-grid-highlight-color-base),0.4);

    --xpool-grid-selected-order-background-color  : rgba(194,219,168,0.5);

    --xpool-grid-inBalance-order-background-color : #F8F8F8;
    --xpool-grid-executed-order-background-color  : #F8F8F8;
    --xpool-grid-rejected-order-background-color  : #FFCAC0;
    --xpool-grid-onHold-order-background-color    : #FDFFDB;
    --xpool-grid-inactive-rowbackground-color     : #F1F1F1;

    --xpool-grid-header-font-weight:        400;
    --xpool-grid-font-size:                 15px;
    --xpool-grid-header-text-color:         black;

    --xpool-grid-row-border:                1px solid #ebe7e2;
    --xpool-grid-header-border:             2px solid lightslategray;
    --xpool-grid-header-group-border:       1px solid RGB(191, 197, 202);

    /*--xpool-grid-background-color:          none;*/
    /*--xpool-grid-rows-background-color:      white;*/
    /*--xpool-grid-dropped-background-color:  rgba(255,255,255,0.3);*/



    /*------ user message styling ---------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-user-message-ok-background-color:     #CEFFD2;
    --xpool-user-message-info-background-color:   #E2FFF7;
    --xpool-user-message-warning-background-color:#FDFFDB;
    --xpool-user-message-error-background-color:  #FFCAC0;

    --xpool-user-message-grid-ok-background-color:     rgba(149,255,158,0.5);
    --xpool-user-message-grid-info-background-color:   rgba(200,255,240,0.5);
    --xpool-user-message-grid-warning-background-color:rgba(252,255,184,0.5);
    --xpool-user-message-grid-error-background-color:  rgba(255,148,127,0.5);

    --xpool-user-message-text-color:        #434343;

    --userMessageSlideHeight: -33px;

    /*------ run state styling ------------------------------------------*/
    /*-------------------------------------------------------------------*/

    --xpool-client-run-status-fetching-balances-background-color:     rgba(199,255,240,0.5);
    --xpool-client-run-status-waiting-for-execution-background-color: rgba(179,255,185,0.5);
    --xpool-client-run-status-executing-background-color:             rgba(99 ,248,111,0.5);
    --xpool-client-run-status-run-ok-background-color:                rgba(0  ,212,16 ,0.5);
    --xpool-client-run-status-warning-background-color:               rgba(252,255,184,0.5);
    --xpool-client-run-status-failed-background-color:                rgba(255,148,127,0.5);
    --xpool-client-run-status-disabled-background-color:              rgba(213,213,213,0.5);


    /*------ general typography -----------------------------------------*/
    /*-------------------------------------------------------------------*/
    --xpool-font-family:                    Lato, sans-serif;
    --xpool-header-font-family:             Lato, sans-serif;

    --xpool-standard-font-weight:           300;
    --xpool-standard-font-size:             16px;
    --xpool-body-text-color:                #434343;

    --xpool-header-font-weight:             400;
    --xpool-header-text-color:              #6AA5C1;

    --xpool-bold-font-weight:               600;

    --xpool-modal-label-font-weight:        500;
    --xpool-modal-label-font-size:          20px;

    --xpool-tooltip-font-weight:            100;
    --xpool-tooltip-font-size:              12px;

    --xpool-table-font-size:                14px;

    --xpool-black-text-color:               black;


    /*------ other ------------------------------------------------------*/
    /*-------------------------------------------------------------------*/
    --xpool-line-height:                    25px;

    --xpool-focus-box-shadow:               0 0 10px 2px rgba(0, 0, 0, 0.2);

}
