.overallExposureHeader {
    padding: 5px 0 5px 0;
    margin: 0 20px 10px 20px;
    color: var(--xpool-body-text-color);
    border-top:    var(--xpool-grid-header-border);
    border-bottom: var(--xpool-grid-header-border);
    text-align: center;
}

.overallExposureContainer {
    padding: 0px 0px 0px 0px;
}
.overallExposureButtonsContainer {
    text-align: center;
}
