.userMessagesContainer {
    width: 100%;
    margin-top: 47px;
    padding: 2px;
    position: sticky;
    top: 50px;
    z-index: 500;
}
.userMessagesContainerNoMenu {
    margin-top: -3px;
    top: 0;
}
.confirmMessageButton {
    float: right;
    /*display: inline-block;*/
    text-align: right;
    width: 20px;
    vertical-align: middle;
    border-radius: 10px;
    margin: 2px 0px 2px 0px;
}

.confirmMessageButton:hover {
    background: rgba(0,0,0,0.1);
    border-style: solid;
    border-color: darkslategray;
    cursor: pointer;
}
.confirmMessageButton:active {
    background: rgba(0,0,0,0.4);
    border-style: solid;
    border-color: darkslategray;
    cursor: pointer;
}
.userMessageTopicDisplay {
    display: inline-block;
    width: 95px;
    /*border: 1px solid darkgray;*/
    /*background: lightgray;*/
    border-radius: 3px;
    font-size: 14px;
    padding: 2px 2px 2px 5px;
    margin: 0px 5px 0px 0px;
    text-align: left;
    color: darkgray;
}

.userMessage {
    display: inline-block;
    width: 100%;
    border: 1px solid slategray;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 2px;
    text-align: left;
    color: var(--xpool-user-message-text-color);
}

button.xPoolButtonInUserMessage {
    height: 24px;
    margin: 0px 7px 0px 0px;
    padding: 2px 5px 2px 5px;
    font-size: var(--xpool-button-in-table-font-size);
    font-weight: 600;
}

.userMessageEnter {
    animation: slide-down-fade-id 0.3s backwards ease-in-out;}
.userMessageExit {
    animation: slide-up-fade-out 0.3s backwards ease-in-out;}
.userMessageSlideUp {
    animation: slide-up 0.3s backwards ease-in-out;}
.userMessageSlideDown {
    animation: slide-dwn 0.3s backwards ease-in-out;}

@keyframes slide-down-fade-id {
    from {
        transform: translateY(-100%);
        opacity: 0;}
    to {
        transform: translateY(0%);
        opacity: 1;}
}
@keyframes slide-up-fade-out {
    from {
        transform: translateY(0%);
        opacity: 1;}
    to {
        transform: translateY(-100%);
        opacity: 0;}
}
@keyframes slide-dwn {
    from { transform: translateY(var(--userMessageSlideHeight)); }
    to   { transform: translateY(0px); }
}
@keyframes slide-up {
    from { transform: translateY(0px); }
    to {   transform: translateY(var(--userMessageSlideHeight)); }
}



.appContainerSlideUp {
    animation: slide-up-var 0.3s backwards ease-in-out;
}
@keyframes slide-up-var {
    from { transform: translateY(0px); }
    to {   transform: translateY(var(--userMessageSlideHeight)); }
}
.appContainerSlideDown {
    animation: slide-dwn-var 0.3s backwards ease-in-out;
}
@keyframes slide-dwn-var {
    from { transform: translateY(var(--userMessageSlideHeight)); }
    to   { transform: translateY(0px); }
}


.userInfoMessage {
    background: var(--xpool-user-message-info-background-color);}
.userWarningMessage {
    background: var(--xpool-user-message-warning-background-color);}
.userErrorMessage {
    background: var(--xpool-user-message-error-background-color);}
