
.xpChartReferenceLine {
    border: none;
    height: 12px;
    line-height: 12px;
    text-align: center;
    padding: 0;
    /*position: absolute;*/
    font-size: 12px;
    background-color: white;
    opacity: 1;
}

.xpChartYLineLabel {
    border: none;
    height: 12px;
    line-height: 12px;
    text-align: center;
    padding: 0;
    /*position: absolute;*/
    font-size: 12px;
    background-color: white;
    opacity: 1;
}
