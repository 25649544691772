div.wizard{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr 5fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:    "wizardReset wizardTop wizardTop wizardFileReader"
                            "wizardDivider wizardDivider wizardDivider wizardDivider"
                            "wizardStep wizardStep wizardStep wizardStep"
                            ". wizardContent wizardContent ."
                            "wizardSummary wizardSummary wizardSummary wizardSummary";
    align-items: start;
    justify-items: start;
}
div.wizardTop{
    grid-area: wizardTop;
    width: 100%;
    height: 40px;
    text-align: center;
}
div.wizardReset{
    grid-area: wizardReset;
    width: 100%;
    height: 40px;
    text-align: center;
}
div.wizardFileReader{
    grid-area: wizardFileReader;
    width: 100%;
    height: 40px;
    text-align: center;
}
div.wizardDivider{
    grid-area: wizardDivider;
    width: 100%;
    min-height: 15px;
}
div.wizardBack{
    grid-area: wizardBack;
    width: 100%;
    text-align: center;
}
div.wizardStep{
    grid-area: wizardStep;
    width: 100%;
}
div.wizardForward{
    grid-area: wizardForward;
    width: 100%;
    text-align: center;
}
div.wizardContent{
    grid-area: wizardContent;
    width: 100%;
    padding: 0 15px 7px 15px;
    border-bottom: 1px solid lightgrey;
}
.wizardContentPlacer{
    min-height: 500px;
    width: 100%;
    /*
    padding-bottom: 7px;
    */
}
.fileReaderSpace {
    width: 100%;
    min-height: 50px;
}
.wizardFormPlacer {
    min-height: 440px;
    width: 100%;
}
div.wizardSummary{
    grid-area: wizardSummary;
    width: 100%;
}
i.arrow {
    border: solid black;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    cursor: pointer;
}
.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.wizardPhaser {
    width: 100%;
}
.wizardInstruction {
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 25px;
}
div.stepElement {
    width: 100%;
    height: 60px;
    text-align: center;
}

.wizardSummaryContainer {
    width: 100%;
    display: grid;
}
.wizardSummaryHeading {
    width: 100%;
    text-align: center;
    margin: 10px 0 15px 0;
}
.wizardSummaryStepContainer {
    width: 100%;
    display: block;
    padding: 0 5px 0 5px;
}
.wizardSummaryStepHeading {
    background-color: #F8F8F8;
    width: 100%;
    text-align: left;
    margin: 4px 0 6px 0;
}
.wizardSummaryStep {
    width: 100%;
    background-color: #F8F8F8;
}
table.summaryFields {
    width: 100%;
}
td.groupingRow{
    font-weight: bold;
}
td.summaryFieldText {
    font-weight: lighter;
    font-size: 15px;
}
td.summaryFieldValue {
    font-weight: normal;
    font-size: 15px;
}
div.summaryText {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
    color: var(--xpool-button-text-color);
    text-align: center;
}
/*
label.fileButton{
    position: relative;
    appearance: none;
    padding: 0.05rem 1rem;
    text-transform: var(--xpool-button-text-transform);
    font-size: var(--xpool-button-font-size);
    font-weight: var(--xpool-button-font-weight);
    color: var(--xpool-button-text-color);
    height: var(--xpool-button-height);
    border: var(--xpool-button-border);
    border-radius: var(--xpool-button-border-radius);
    margin: 7px 7px 7px 0;
    background-color: var(--xpool-button-background-color);
    transition: background 0.3s, border 0.3s, color 0.3s;
    line-height: 30px;
    width: 120px;
    cursor: pointer;
}
label.fileButton:hover{
    background-color: var(--xpool-button-hover-background-color);
    border: var(--xpool-button-hover-border);
    color: var(--xpool-button-hover-text-color);
    font-weight: var(--xpool-button-hover-font-weight);
}*/
#wizardFile{
    display: none;
}
.wizardFormCard {
    max-width: 500px;
    min-width: 350px;
    width: 25%;

    margin: 5px 5px 5px 0px;
    padding: 0 7px 7px 7px;

    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    background: ghostwhite;
}
.wizardFormCardLarge {
    min-width: 500px;
    max-width: unset;
    width: calc(50% - 10px);
}
.wizardFormCardHeader {
    margin-bottom: 10px;
}
.submitRow {
    text-align: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}



