
.xpoolModal {
    position: absolute;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    opacity: 0;

    max-height: 100%;

    background: white;
    padding: 10px;
    border: 1px solid gray;
    width: 400px;
    min-width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
}

.xpoolModalConfirmWithComment {
    width: 500px;
}

.xpoolModalReport {
    width: 800px;
}

.xpoolModalLangEdit {
    width: 60%;
}
.xpoolModalAudit {
    width: 90%;
}

.xpoolModalFileReaderErrorMessage {
    width: 60%;
}
.xpoolModalGenerateUserMessage {
    width: 40%;
}
.xpoolModalGenerateUserMessageRow {
    width: 100%;
    padding-bottom: 12px;
}
.xpoolModalGenerateUserMessageInlineSelect {
    float: right;
    width: 30%;
    margin-top: 0;
}

.xpoolModalAfterOpen {
    opacity: 1;
}

.xpoolModalBeforeClose {
    opacity: 0;
}

.xpoolModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;
}

.xpoolModalOverlayAfterOpen {
    background-color: rgba(0, 0, 0, 0.5);
}

.xpoolModalOverlayBeforeClose {
    background-color: rgba(0, 0, 0, 0);
}

.xpoolModalButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.xpoolModalButtonsCenter{
    display:flex;
    justify-content:center;
    align-items:flex-end;
}
.xpoolModalHeader {
    padding-left: 5px;
    margin: 0;
    margin-bottom: 10px;
    color: var(--xpool-header-text-color);
    text-align: center;
    font-size: var(--xpool-modal-label-font-size);
    font-weight: var(--xpool-modal-label-font-weight);
}

.modalBodyHighestAlert {
    background-color: white;
    animation-name: highestAlertAnimation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes highestAlertAnimation {
    0% {
        background-color: white;
    }
    80% {
        background-color: #FFD6D7;
    }
    95% {
        background-color: rgba(141,5,5);
    }
    100% {
        background-color: white;
    }
}
