.marketSimulatorInactive {
    background: none;
}
.marketSimulatorPosted {
    background: none;
}
.marketSimulatorActive {
    background: none;
}
.marketSimulatorConfigItem {
    background: ghostwhite;
    border: lightskyblue solid 1px;
    border-radius: 8px;
    padding: 6px;
    position: relative;
    margin-bottom: 10px;
    min-height: 130px;
}
