h4.instanceUserRightsLabel {
    font-weight: var(--xpool-header-font-weight);
    margin: 0;
    margin-top: 0px;
    margin-bottom: 5px;
    color: var(--xpool-header-text-color);
}
.instanceUserRightsCard {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 5px;
    padding-left:  7px;
    padding-right: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    /*background: ghostwhite;*/
}
.instanceUserRightsCardAdded {
    background-color: var(--xpool-form-card-new-background-color);
}
.instanceUserRightsCardRemoved {
    background-color: whitesmoke;
    font-size: var(--xpool-old-value-font-size);

    /*font-weight: var(--xpool-old-value-font-weight);*/
    color: gray;
    font-style: italic;
}
.instanceUserRightsBlock {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    border-radius: 0px;
    border-color: lightgray;
    border-style: solid;
    border-width: 0 1px 0 0;
    /*background: ghostwhite;*/
}

.adminUserRightsContainer{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
}
.adminUserRightsAssignButtons {
    text-align: center;
    vertical-align: center;
    width    : 193px;
    /*border: solid 1px gray;*/
    padding: 40px 0 0 0;
    margin-left: 7px
}
.adminUserRightsEntityList {
    min-width    : 300px;
    max-width    : 800px;
    width: calc(50% - 100px);
}
